import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Footer } from 'src/Model/WebCustomer';
import { ApiService } from '../service/api.service';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';

@Component({
  selector: 'app-new-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  currentDateStr: string = new Date().getFullYear().toString();
  FooterColor = '#DDDDDD';
  WebCustomerSub: Subscription;
  BrandFlavour: string;
  BrandName: string;
  UnsubResult: string;
  UnsubField: string;
  unsubscribeModel: boolean;

  Footer: Footer = Footer;

  constructor(
    private _local: GetterService,
    private _requestService: RequestService,
    private apiService: ApiService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.getWebCustomer();
  }
  ngOnDestroy(): void {
    this.WebCustomerSub.unsubscribe();
  }
  getWebCustomer() {
    this.WebCustomerSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      const footer = res.TemplateData.Footer;
      const termsConditions = footer?.TermsConditions;
      const privacypolicy = footer?.PrivacyPolicy;
      const unsub = footer?.Unsubscribe;
      const partners = footer?.Partners;
      const brand = footer?.Brand;

      if (termsConditions) {
        if (typeof termsConditions?.Enabled != 'undefined')
          this.Footer.TermsConditions.Enabled = termsConditions.Enabled;

        if (typeof termsConditions?.Text != 'undefined')
          this.Footer.TermsConditions.Text = termsConditions.Text;

        if (typeof termsConditions?.URL != 'undefined')
          this.Footer.TermsConditions.URL = termsConditions.URL;
      }

      if (privacypolicy) {
        if (typeof privacypolicy?.Enabled != 'undefined')
          this.Footer.PrivacyPolicy.Enabled = privacypolicy.Enabled;

        if (typeof termsConditions?.Text != 'undefined')
          this.Footer.PrivacyPolicy.Text = privacypolicy.Text;

        if (typeof termsConditions?.URL != 'undefined')
          this.Footer.PrivacyPolicy.URL = privacypolicy.URL;
      }

      if (unsub) {
        if (typeof unsub?.Enabled != 'undefined')
          this.Footer.Unsubscribe.Enabled = unsub.Enabled;

        if (typeof unsub?.Text != 'undefined')
          this.Footer.Unsubscribe.Text = unsub.Text;
      }

      if (partners) {
        if (typeof partners?.ImageURL != 'undefined')
          this.Footer.Partners.ImageURL = partners.ImageURL;

        if (typeof partners?.Enabled != 'undefined')
          this.Footer.Partners.Enabled = partners.Enabled;

        if (typeof partners?.Text != 'undefined')
          this.Footer.Partners.Text = partners.Text;

        if (typeof partners?.URL != 'undefined')
          this.Footer.Partners.URL = partners.URL;
      }

      if (brand) {
        if (typeof brand?.ImageURL != 'undefined')
          this.Footer.Brand.ImageURL = brand.ImageURL;

        if (typeof brand?.Enabled != 'undefined')
          this.Footer.Brand.Enabled = brand.Enabled;

        if (typeof brand?.Prefix != 'undefined')
          this.Footer.Brand.Prefix = brand.Prefix;

        if (typeof brand?.Postfix != 'undefined')
          this.Footer.Brand.Postfix = brand.Postfix;

        if (typeof brand?.URL != 'undefined') this.Footer.Brand.URL = brand.URL;
      }

      this.BrandFlavour = res.BrandData.BrandFlavor;
      this.BrandName = res.BrandData.BrandName;
      this.FooterColor =
        res.TemplateData.DynamicStyle.TertiaryColors.FooterColor;
    });
  }

  openUrl(url) {
    this._router.navigateByUrl(this._requestService.setUrl(url));
  }

  redirect(url: string) {
    window.location.href = url;
  }

  unsubscribe() {
    let body = {
      BrandFlavor: this.BrandFlavour,
      Token: this.UnsubField,
    };
    this._requestService
      .postRequest('api/Customer/UnsubscribeCustomerMarketingPreferences', body)
      .subscribe((res) => {
        if (res['Info']['Status'] == 200) {
          this.UnsubResult = 'Unsubscribed Successfully';
        } else {
          this.UnsubResult = res['Info']['Message'];
        }
      });
  }
}

const Footer = {
  TermsConditions: {
    Enabled: true,
    Text: 'Terms & Conditions',
    URL: '',
  },
  PrivacyPolicy: {
    Enabled: true,
    Text: 'Privacy Policy',
    URL: '',
  },
  Unsubscribe: {
    Enabled: true,
    Text: 'Unsubscribe',
  },

  Partners: {
    ImageURL:
      'https://perkss.blob.core.windows.net/images/customer/footer/takeaway_tree.png',
    Enabled: true,
    URL: '',
    Text: 'Takeaway Tree',
  },

  Brand: {
    ImageURL: '',
    Enabled: true,
    Prefix: 'Powered By',
    Postfix: 'Perkss',
    URL: 'https://perkss.co.uk/',
  },
};
