import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectionCheckComponent } from './connection-check.component';

@NgModule({
  declarations: [ConnectionCheckComponent],
  imports: [CommonModule],
  exports: [ConnectionCheckComponent],
})
export class ConnectionCheckModule {}
