import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/Model/Product-Interfaces';

@Component({
  selector: 'app-car-listing',
  templateUrl: './searched-car.component.html',
  styleUrls: ['./searched-car.component.scss'],
})
export class SearchedCarComponent implements OnInit {
  @Input() product: Product;
  @Input() redirectToDetails: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  openUrl() {
    if (this.redirectToDetails) {
      const brandFlavor = window.location.pathname.split('/')[1];
      window.open(
        `${window.location.protocol}//${window.location.host}/${brandFlavor}/stock-list/details/${this.product.ProductId}`,
        '_blank'
      );
      return;
    }
    const brandFlvaor = window.location.pathname.split('/')[1];
    let component = window.location.pathname.split('/')[2];
    if (component == 'category-products') component = 'stock-list';

    this.router.navigateByUrl(
      `${brandFlvaor}/${component}/details/${this.product.ProductId}`
    );
  }
  enquireNow() {
    window.location.href = 'https://camcarsales.co.uk/contact/';
  }
}
