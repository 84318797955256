import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GetterService } from '../app/service/getter-service.service';
import { Subscription } from 'rxjs';
import { ApiService } from './service/api.service';
import { WebCustomer } from 'src/Model/WebCustomer';
import { environment } from 'src/environments/environment';
import { LoginService } from './login/login.service';
import { Meta, Title } from '@angular/platform-browser';
declare let fbq: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isHeaderVisible: boolean = true;
  isFooterVisible: boolean = true;
  isWhatsAppVisible = true;
  isAllergenseFooterVisible: boolean = false;
  isBottomMenuVisible: boolean = true;
  whatsappno: number;
  brand_Name: string;
  /*cart id*/
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  fbVerification: HTMLMetaElement = document.querySelector('#fbVerification');
  fbPixelScript: HTMLScriptElement;
  /* Bottom Menu */
  bottomMenu: boolean = false;

  webCusSub: Subscription;

  constructor(
    private _router: Router,
    private _local: GetterService,
    private apiService: ApiService,
    private login: LoginService,
    private meta: Meta,
    private title: Title
  ) {
    this.login.getUserData();

    const app = window.location.pathname.split('/')[2] === 'app-menu';

    if (!JSON.parse(this._local.getSessionItem('app'))) {
      this._local.setSessionItem('app', app);
    }
    environment.production;
    console.log(
      '%c' + 'APP VERSION : ' + environment.appVersion,
      'background: red; color: #fff;padding:10px'
    );

    if (this._local.get('access_token') == 'guest') {
      this._local.remove('guestEmail');
      this._local.remove('guestLastName');
      this._local.remove('guestFirstName');
      this._local.remove('guestContact');
      this._local.remove('access_token');
    }
  }

  ngOnInit() {
    this._local.remove('deliveryType'); //for RecipeListView postcode validation

    /** 
    For stripe parallel Order Protection in case it is not removed during request cycle then refereh page will
    Remove the flag to allow future submissions
    */
    this._local.remove('isSubmitting');

    this.isHeaderVisible = true;
    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) document.body.scrollTop = 0;
    });
    this.getwebCustomer();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  setHeaderVisibility(visible: boolean) {
    this.isHeaderVisible = visible;
  }
  setBottomMenuVisibility(visible: boolean) {
    this.isBottomMenuVisible = visible;
  }
  setFooterVisibility(visible: boolean) {
    this.isFooterVisible = visible;
  }
  setAllergensFooter(visible: boolean) {
    this.isAllergenseFooterVisible = visible;
  }
  setWhatsAppVisibilty(visible: boolean) {
    this.isWhatsAppVisible = visible;
  }

  getwebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.bottomMenu = res.TemplateData.BottomMenu?.IsEnabled;
      this.favIcon.href = res.TemplateData.BusinessInfo.FavIcon;
      this.whatsappno = res.TemplateData.BusinessInfo.WhatsAppNo;
      this.brand_Name = res.BrandData.BrandName;

      //Set Meta Data for SEO
      this.title.setTitle(res.BrandData.BrandName);

      this.meta.updateTag({
        name: 'keywords',
        content: res.TemplateData.HomeTemplate.Keywords,
      });

      this.meta.updateTag({
        name: 'description',
        content: res.TemplateData.HomeTemplate.TagLine,
      });

      // For Favicon Icon in SEO and multiple browser support
      this.meta.updateTag({
        name: 'icon',
        rel: 'icon',
        href: res.TemplateData.BusinessInfo.FavIcon,
        type: 'image/x-icon',
      });

      this.meta.updateTag({
        name: 'shortcut icon',
        rel: 'shortcut icon',
        href: res.TemplateData.BusinessInfo.FavIcon,
        type: 'image/x-icon',
      });
      // For Favicon Icon in SEO and multiple browser support// END

      this._local.set('BrandId', res.BrandData.BrandId);
      this.addFbCode(res);
      this.addGoogleTag(res);
    });
  }

  addFbCode(res: WebCustomer) {
    if (!res.TemplateData?.GoogleTag) return;

    let s = window.document.createElement('script');
    s.id = 'GoogleTag';
    s.type = 'text/javascript';
    s.innerHTML = res.TemplateData.GoogleTag;
    window.document.body.appendChild(s);
  }

  addGoogleTag(res: WebCustomer) {
    if (!res.TemplateData?.FacebookPixelCode?.script) return;

    this.fbVerification.setAttribute(
      'content',
      res.TemplateData.FacebookPixelCode.fbverification
    );

    let s = window.document.createElement('script');
    s.id = 'fbPixelScript';
    s.type = 'text/javascript';
    s.innerHTML = res.TemplateData.FacebookPixelCode.script;
    s.onload = () => {
      fbq('track', 'PageView');
    };
    window.document.body.appendChild(s);
  }

  openMobileWhatsApp() {
    window.open(
      'https://api.whatsapp.com/send?phone=' + this.whatsappno,
      '_blank'
    );
  }

  openWhatsApp() {
    window.open(
      'https://web.whatsapp.com/send?phone=' + this.whatsappno,
      '_blank'
    );
  }
}
