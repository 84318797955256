<div *ngFor="let recipe of recipes">
  <div
    class="RecipeCard bg-light"
    [ngStyle]="{ 'background-color': ItemBackgroundColor }"
  >
    <div class="d-flex">
      <div *ngIf="recipe?.RecipeImage" class="recipeImage br-10px">
        <img src="{{ recipe?.RecipeImage }}" alt="Recipe Image" />
      </div>
      <div class="ms-2 RecipeTitleContainer">
        <div class="recipe-Title-Des">
          <div>
            <h2
              class="mb-0"
              [ngStyle]="
                !recipe.RecipeStock
                  ? { color: 'lightgrey' }
                  : { color: ItemTitleColor }
              "
            >
              {{ recipe?.RecipeTitle }}
              <span *ngIf="recipe.IsNewlyAdded" id="New-Recipe">New</span>
              <span *ngIf="recipe.IsPopular" id="popular-Recipe">Popular</span>
              <span *ngIf="!recipe.RecipeStock" class="text-danger">
                - {{ outOfStockLabelText || "Coming Soon" }}
              </span>
            </h2>
            <p class="recipeDesc" [ngStyle]="{ color: ItemDescriptionColor }">
              {{ recipe?.RecipeDescription }}
            </p>
          </div>
        </div>
        <div class="product-footer d-flex flex-wrap">
          <span
            class="text-custom-white square-tag"
            *ngFor="let allergen of recipe.Allergens"
          >
            <img src="{{ allergen }}" alt="tag" />
          </span>
        </div>
      </div>
    </div>
    <div class="price-Cart">
      <span
        (click)="recipe.RecipeStock && addTocart ? getRecipeModal(recipe) : ''"
        *ngIf="!recipe?.DisplayAmountText?.trim()?.length"
        style="border-radius: 40px; padding: 5px; font-size: large"
        [ngStyle]="{
          color: RecipePriceTextColor,
          border: '1px solid' + RecipePriceBorderColor,
          'background-color': RecipePriceBackgroundColor
        }"
        >{{ recipe?.RecipePrice | currency : "GBP" : "symbol" : "1.2-2" }}
      </span>
      <span
        class="price"
        (click)="recipe.RecipeStock && addTocart ? getRecipeModal(recipe) : ''"
        *ngIf="recipe?.DisplayAmountText?.trim()?.length"
        [ngStyle]="{
          color: RecipePriceTextColor,
          border: '1px solid ' + RecipePriceBorderColor,
          'background-color': RecipePriceBackgroundColor
        }"
      >
        {{ recipe?.DisplayAmountText }}
      </span>
      <span
        *ngIf="recipe.RecipeStock && addTocart"
        (click)="getRecipeModal(recipe)"
        class="cursor-pointer"
      >
        <i
          class="fas fa-plus-square"
          [ngStyle]="{ color: MenuCartColor }"
          aria-hidden="true"
          style="font-size: xx-large"
        ></i>
      </span>
      <span
        *ngIf="recipe?.RecipeExtraObject && dineIn"
        (click)="getRecipeModal(recipe)"
        class="cursor-pointer"
      >
        <button
          type="button"
          class="btn br-40px"
          [ngStyle]="{
            'background-color': SelectedButtonColor,
            color: SelectedButtonTextColor
          }"
        >
          Options
        </button>
      </span>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>

<app-popup *ngIf="showPopup"></app-popup>
