import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  ErrorHandler,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RequestInterceptor } from './service/request-interceptor';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandlingService } from './service/error-handling.service';
import { SelectDeliveryTypeModule } from './cards/select-delivery-type/select-delivery-type.module';
import { PopupModule } from './popup/popup.module';
import { RecomendationsModule } from './recomendations/recomendations.module';
import { InquiryPopupModule } from './store/inquiry-popup/inquiry-popup.module';
import { SearchModule } from './search/search.module';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import { AllergensFooterModule } from './allergens-footer/allergens-footer.module';
import { BottomMenuModule } from './bottom-menu/bottom-menu.module';
import { WelcomeMessageModule } from './welcome-message/welcome-message.module';
import { ConnectionCheckModule } from './connection-check/connection-check.module';
import { ProgressComponent } from './progress/progress.component';

@NgModule({
  declarations: [AppComponent, ProgressComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SelectDeliveryTypeModule,
    PopupModule,
    RecomendationsModule,
    InquiryPopupModule,
    SearchModule,
    HeaderModule,
    FooterModule,
    AllergensFooterModule,
    BottomMenuModule,
    WelcomeMessageModule,
    ConnectionCheckModule,
  ],
  providers: [
    AppComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    DatePipe,
    { provide: ErrorHandler, useClass: ErrorHandlingService },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
