<!--Desktop View-->

<div class="container-fluid p-0 d-md-block d-none">
  <div *ngIf="MenuWebBanner">
    <img src="{{ MenuWebBanner }}" alt="Menu header image" />
  </div>

  <div class="row m-0 pt-3 pb-3 justify-content-center">
    <div class="col-1 d-flex align-items-center">
      <a href="{{ webLink }}" class="d-block">
        <img src="{{ branch_Logo }}" class="logo-img" alt="{{ brand_Name }}" />
      </a>
    </div>
    <div class="col-6 d-flex align-items-center">
      <app-search *ngIf="showSearch"></app-search>
    </div>
    <div
      class="col-xl-3 col-lg-4 col-5 d-flex justify-content-around align-items-center"
    >
      <span
        class="position-relative"
        (click)="callCheckout()"
        *ngIf="showHeaderCartIcon"
      >
        <button [ngStyle]="{ color: HeaderCartColor }">
          <span *ngIf="!distributorLogin">
            <i class="fa fa-shopping-bag fs-1" aria-hidden="true"></i
          ></span>
          <span class="cartImg cursor-pointer pe-2" *ngIf="distributorLogin">
            <img src="../../assets/img/cart icon.jpg" alt="cart" />
          </span>
        </button>
        <span
          class="counter"
          [ngStyle]="{ 'background-color': HeaderCartCounterColor }"
        >
          {{ _requestService.cartCounter$ | async }}
        </span>
      </span>

      <button
        (click)="openUrl('join-reward')"
        *ngIf="HeaderRewardIcon && customer"
      >
        <img src="../../assets/img/rewards.png" class="rewardIcon" />
      </button>

      <button
        class="dropbtn"
        (click)="openUrl('login')"
        *ngIf="!customer && !distributorLogin"
        [ngStyle]="{
          'border-radius': buttonRadius
        }"
      >
        <i class="fa fa-lock me-2"></i>
        <span>Log In</span>
      </button>

      <button
        class="dropbtn br-10px p-3"
        (click)="openUrl('login')"
        *ngIf="!customer && distributorLogin && allowLogin"
      >
        <i class="fa fa-lock me-2"></i>
        <span>Distributor Login</span>
      </button>

      <div class="dropdown" *ngIf="customer">
        <button class="dropbtn">
          <img
            src="./../../assets/img/avatar.png"
            class="rounded-circle rewardIcon me-2"
            alt="userimg"
            *ngIf="
              headerConfig?.Login?.Image != undefined
                ? headerConfig.Login.Image
                : true
            "
          />
          <span>{{ cusFirstName + " " + cusLastName }}</span>
          <i class="fa fa-caret-down ms-2" aria-hidden="true"></i>
        </button>
        <div class="dropdown-content">
          <a (click)="updateAccount()">Account</a>

          <a (click)="openUrl('refer')" *ngIf="ShowReferSection">
            Refer a friend
          </a>
          <a (click)="signOut()">Sign Out</a>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-flex ps-5 pe-5"
    [ngStyle]="{ 'background-color': HeaderBackgroundColor }"
  >
    <div
      *ngFor="let tile of tiles; let i = index"
      class="ps-2 pe-2 pt-3 pb-3 w-100 fw-500 fs-5 text-center cursor-pointer"
      (click)="redirect(tile)"
      (mouseover)="hover = tile?.['Title']"
      (mouseleave)="hover = ''"
      [ngStyle]="
				hover == tile?.['Title']
					? {
							color: HeaderTextHoverColor,
							'background-color': HeaderHoverBackgroundColor
					  }
					: currentComponent.includes(tile.Redirection)
					? {
							color: HeaderTextHoverColor,
							'background-color': HeaderHoverBackgroundColor
					  }
					: { color: HeaderTextColor }
			"
    >
      {{ tile?.Title }}
    </div>
  </div>
</div>
<!--Desktop View-->

<!--Mobile View-->
<div *ngIf="MobileMenuWebBanner" class="d-block d-md-none">
  <img src="{{ MobileMenuWebBanner }}" alt="Menu header image" />
</div>
<div
  class="container-fluid p-0 d-block d-md-none position-sticky top-0 z-index-400 bgWhite"
  [ngStyle]="{ 'background-color': MobileHeaderBackgroundColor }"
>
  <div class="row align-items-center ps-2 pt-3 pe-2 pb-3">
    <div class="col-2 cursor-pointer pe-0">
      <span
        *ngIf="
          (currentComponent == 'store' ||
            currentComponent == 'shop' ||
            currentComponent == 'order' ||
            currentComponent == 'products') &&
          tempalte == 'Stellar'
        "
        (click)="sideCat = true"
        [ngStyle]="{ color: BurgerMenuColor }"
        ><i class="fas fa-bars fs-1"></i
      ></span>
      <div
        (click)="sideMenu = true"
        *ngIf="tempalte != 'Stellar'"
        [ngStyle]="{ color: BurgerMenuColor }"
      >
        <i class="fas fa-bars fs-1"></i>
      </div>
    </div>
    <div class="col-2 ps-0">
      <a href="{{ webLink }}">
        <img src="{{ branch_Logo }}" class="logo-img" alt="{{ brand_Name }}" />
      </a>
    </div>
    <div
      class="col-8 d-flex align-items-center"
      [ngClass]="{
        'justify-content-end': !HeaderRewardIcon && !customer && !allowLogin,
        'justify-content-around': HeaderRewardIcon || customer || allowLogin
      }"
    >
      <button
        (click)="openUrl('join-reward')"
        *ngIf="HeaderRewardIcon && customer"
      >
        <img src="../../assets/img/rewards.png" class="rewardIcon" />
      </button>

      <div
        class="dropdown"
        *ngIf="customer && !headerConfig?.Login?.Mobile?.LoginButtonOnly"
      >
        <button class="dropbtn p-0">
          <img
            src="./../../assets/img/avatar.png"
            class="rounded-circle rewardIcon"
            alt="userimg"
          />
        </button>
        <div class="dropdown-content">
          <a (click)="updateAccount()">Account</a>
          <a (click)="openUrl('refer')" *ngIf="ShowReferSection"
            >Refer a friend</a
          >
          <a (click)="signOut()">Sign Out</a>
        </div>
      </div>

      <div
        class="dropdown"
        *ngIf="customer && headerConfig?.Login?.Mobile?.LoginButtonOnly"
      >
        <button class="dropbtn">
          <img
            src="./../../assets/img/avatar.png"
            class="rounded-circle rewardIcon me-2"
            alt="userimg"
            *ngIf="
              headerConfig?.Login?.Image != undefined
                ? headerConfig.Login.Image
                : true
            "
          />
          <span>{{ cusFirstName + " " + cusLastName }}</span>
          <i class="fa fa-caret-down ms-2" aria-hidden="true"></i>
        </button>
        <div class="dropdown-content">
          <a (click)="updateAccount()">Account</a>
          <a (click)="openUrl('refer')" *ngIf="ShowReferSection"
            >Refer a friend</a
          >
          <a (click)="signOut()">Sign Out</a>
        </div>
      </div>

      <button
        class="dropbtn"
        (click)="openUrl('login')"
        *ngIf="
          !customer &&
          !distributorLogin &&
          headerConfig?.Login?.Mobile?.LoginButtonOnly
        "
        [ngStyle]="{
          'border-radius': buttonRadius
        }"
      >
        <i class="fa fa-lock me-2"></i>
        <span>Log In</span>
      </button>

      <div
        *ngIf="
          !customer &&
          allowLogin &&
          !headerConfig?.Login?.Mobile?.LoginButtonOnly
        "
        (click)="customer ? '' : openUrl('login')"
      >
        <img
          src="./../../assets/img/avatar.png"
          class="rounded-circle rewardIcon"
          alt="userimg"
          [ngStyle]="customer ? { opacity: '1' } : { opacity: '0.5' }"
        />
      </div>

      <div
        (click)="showMobileSearch = true"
        [ngStyle]="{ color: MobileSearchColor }"
        *ngIf="showSearch"
      >
        <i class="fas fa-search fs-1"></i>
      </div>

      <span
        class="position-relative"
        (click)="callCheckout()"
        *ngIf="showHeaderCartIcon"
      >
        <button [ngStyle]="{ color: HeaderCartColor }">
          <span *ngIf="!distributorLogin">
            <i class="fa fa-shopping-bag fs-1" aria-hidden="true"></i
          ></span>
          <span class="cartImg cursor-pointer pe-2" *ngIf="distributorLogin">
            <img src="../../assets/img/cart icon.jpg" alt="cart" />
          </span>
        </button>
        <span
          class="counter"
          [ngStyle]="{ 'background-color': HeaderCartCounterColor }"
          >{{ _requestService.cartCounter$ | async }}</span
        >
      </span>
    </div>
  </div>
</div>
<!--Mobile View-->

<div class="overlay" *ngIf="showMobileSearch">
  <button
    class="btn btn-danger mobileSearchCloseBtn fs-2 position-relative"
    (click)="showMobileSearch = false"
  >
    &times;
  </button>
  <app-search></app-search>
</div>

<div
  [ngStyle]="sideCat ? {} : { display: 'none' }"
  class="overlay sideCatOverlay"
  (click)="sideCat = false"
>
  <app-side-drawer (closeSideCat)="sideCat = $event"></app-side-drawer>
</div>

<div
  class="overlay sideMenuOverlay"
  (click)="sideMenu = false"
  *ngIf="sideMenu"
>
  <div class="sideMenuBox w-50 bg-light pt-5">
    <div
      *ngFor="let tile of tiles"
      class="w-100 p-2"
      [ngStyle]="
        currentComponent.includes(tile.Redirection)
          ? {
              color: HeaderTextHoverColor,
              'background-color': HeaderHoverBackgroundColor
            }
          : {}
      "
    >
      <p
        (click)="redirect(tile)"
        (click)="sideMenu = false"
        class="fs-5 m-0 cursor-pointer"
      >
        <Strong>{{ tile?.Title }}</Strong>
      </p>
    </div>
    <hr />
    <p
      [ngStyle]="
        currentComponent == 'updateaccount'
          ? {
              color: HeaderTextHoverColor,
              'background-color': HeaderHoverBackgroundColor
            }
          : {}
      "
      (click)="updateAccount()"
      class="fs-5 m-0 p-2 cursor-pointer"
    >
      <strong>Account</strong>
    </p>
    <p
      [ngStyle]="
        currentComponent == 'refer'
          ? {
              color: HeaderTextHoverColor,
              'background-color': HeaderHoverBackgroundColor
            }
          : {}
      "
      (click)="openUrl('refer')"
      *ngIf="ShowReferSection"
      class="fs-5 m-0 p-2 cursor-pointer"
    >
      <strong>Refer a friend</strong>
    </p>

    <hr />

    <p
      *ngIf="customer"
      (click)="sideMenu = false; signOut()"
      class="fs-5 m-0 text-danger p-2 cursor-pointer"
    >
      <Strong>Sign Out</Strong>
    </p>
    <p
      *ngIf="!customer"
      (click)="sideMenu = false; openUrl('login')"
      class="fs-5 m-0 text-danger p-2 cursor-pointer"
    >
      <Strong>Log In</Strong>
    </p>
  </div>
</div>
