import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-check',
  templateUrl: './connection-check.component.html',
  styleUrls: ['./connection-check.component.scss'],
})
export class ConnectionCheckComponent implements OnInit {
  online = true;
  constructor() {}

  ngOnInit(): void {

    window.addEventListener('offline', (e) => {
     
      this.online = false;
    });
    window.addEventListener('online', (e) => {
   
      this.online = true;
      this.referesh();
    });
  }

  referesh() {
    window.location.reload();
  }
}
