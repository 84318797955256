import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Product } from 'src/Model/Product-Interfaces';
import { Recipe } from 'src/Model/Product-Interfaces';
import { StopOnlineOrders } from 'src/Model/WebCustomer';
import { popupService } from '../popup/popup.service';
import { ApiService } from '../service/api.service';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
import { UserService } from '../service/user.service';
import { STOPONLINEORDERS } from '../side-cart/side-cart.component';
import { InquiryPopupService } from '../store/inquiry-popup/inquiry-popup.service';
import { recomendationsService } from './recomendations.service';

@Component({
  selector: 'app-recomendations',
  templateUrl: './recomendations.component.html',
  styleUrls: ['./recomendations.component.scss'],
})
export class RecomendationsComponent implements OnInit, OnDestroy {
  brandFlavour: string;
  checkOutOff: boolean;
  ShowCheckOutOffMsg: string = '';
  recommendations;
  recomendedRecipies: Array<Recipe> = [];
  recomendedRecipiesBackup: Array<Recipe> = [];
  recomendedProducts: Array<Product> = [];
  recomendedProductsBackup: Array<Product> = [];
  SelectedButtonColor;
  SelectedButtonTextColor;
  simpleCheckout: boolean;
  distributorLogin: boolean;
  recomendationsButtonText: string;

  checkOutActive: boolean;
  StopOnlineDeliveryOrders: boolean;
  StopOnlineCollectionOrders: boolean;
  StopOnlineEatInOrders: boolean;
  stopOnlineOrders: StopOnlineOrders = STOPONLINEORDERS;

  subs: Subscription[] = [];
  deliveryType: string;

  constructor(
    private _requestService: RequestService,
    private recomendationsService: recomendationsService,
    private router: Router,
    private apiService: ApiService,
    private popupService: popupService,
    private _local: GetterService,
    private inquiryService: InquiryPopupService,
    private user: UserService
  ) {}

  ngOnInit(): void {
    this.brandFlavour = window.location.pathname.split('/')[1];

    const sub = this._requestService.deliveryType$.subscribe((type) => {
      this.getRecommendedRecipes();
    });

    this.subs.push(sub);

    const sub2 = this.recomendationsService.showRecomendations.subscribe(() => {
      if (!this.checkOutActive) {
        this.ShowCheckOutOffMsg = 'We are not currently taking any orders';
        return;
      }
      
      if (
        this._requestService.deliveryType$.value == 'Home' &&
        this.StopOnlineDeliveryOrders
      ) {
        this.ShowCheckOutOffMsg = this.stopOnlineOrders.Delivery.Message;
        return;
      }

      if (
        this._requestService.deliveryType$.value == 'Store' &&
        this.StopOnlineCollectionOrders
      ) {
        this.ShowCheckOutOffMsg = this.stopOnlineOrders.Collection.Message;
        return;
      }

      if (
        this._requestService.deliveryType$.value == 'EatIn' &&
        this.StopOnlineEatInOrders
      ) {
        this.ShowCheckOutOffMsg = this.stopOnlineOrders.EatIn.Message;
        return;
      }

      const component = window.location.pathname.split('/')[2];
      if (component == 'store' && !this._local.get('access_token')) {
        this.inquiryService.showPopup();
        return;
      }
      let cartRecipeItems = JSON.parse(this._local.get('cartRecipeItems'));
      let cartProductItems = JSON.parse(this._local.get('cartProductItems'));
      let recomendedRecipies = this.recomendedRecipies;
      let recomendedProducts = this.recomendedProducts;
      cartRecipeItems?.forEach((element) => {
        recomendedRecipies?.forEach((recipe, index) => {
          if (recipe != null) {
            if (
              recipe.RecipeId == element.RecipeId ||
              recipe.RecipeTitle == element.RecipeTitle
            ) {
              recomendedRecipies[index] = null;
            }
          }
        });
      });
      cartProductItems?.forEach((element) => {
        recomendedProducts?.forEach((product, index) => {
          if (product != null) {
            if (
              product.ProductId == element.ProductId ||
              product.ProductTitle == element.ProductTitle
            ) {
              recomendedProducts[index] = null;
            }
          }
        });
      });
      this.recomendedRecipies = [];
      this.recomendedProducts = [];
      recomendedRecipies?.forEach((recipe, index) => {
        if (recipe != null) {
          this.recomendedRecipies.push(recipe);
        }
      });
      recomendedProducts?.forEach((product, index) => {
        if (product != null) {
          this.recomendedProducts.push(product);
        }
      });
      if (this.recomendedRecipies.length || this.recomendedProducts.length) {
        this.recommendations = true;
        return;
      }
      if (!this.recomendedRecipies.length || !this.recomendedProducts.length) {
        this.openUrl('checkout');
      }
    });
    this.subs.push(sub2);

    const sub3 = this.popupService.addedToCartSuccessful.subscribe((data) => {
      if (this.recommendations) {
        if (
          this.recomendedRecipies.length == 1 ||
          this.recomendedProducts.length == 1
        ) {
          this.openUrl('checkout');
          return;
        }
        if (this.recomendedRecipies.length) {
          this.recomendedRecipies.forEach((recipe, index) => {
            if (recipe.RecipeId == data.RecipeId) {
              this.recomendedRecipies.splice(index, 1);
            }
          });
        } else {
          this.recomendedProducts.forEach((product, index) => {
            if (product.ProductId == data.ProductId) {
              this.recomendedProducts.splice(index, 1);
            }
          });
        }
      }
    });
    this.subs.push(sub3);

    this.getWebCustomer();
  }

  getWebCustomer() {
    const sub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.recomendationsButtonText =
        res.TemplateData.CheckoutButtonsText?.RecomendationsButtonText;
      this.simpleCheckout = res.TemplateData?.CheckoutDetail?.simpleCheckout;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;

      this.checkOutActive = res.TemplateData.CheckoutDetail.Active;

      if (typeof res.TemplateData.CheckoutDetail?.Active == 'undefined') {
        this.checkOutActive = true;
      }

      if (res.TemplateData.CartMessage?.StopOnlineOrders)
        this.stopOnlineOrders = res.TemplateData.CartMessage?.StopOnlineOrders;

      this.getConfig();
    });

    this.subs.push(sub);
  }

  getConfig() {
    const sub = this.apiService.getbrandConfig$.subscribe((res) => {
      if (!res) return;
      this.checkOutOff = res.Active;
      this.StopOnlineDeliveryOrders = res.StopOnlineDeliveryOrders;
      this.StopOnlineCollectionOrders = res.StopOnlineCollectionOrders;
      this.StopOnlineEatInOrders = res.StopOnlineEatInOrders;
    });

    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  getRecommendedRecipes() {
    let url = 'api/Recipe/RecommendedItems/' + this.brandFlavour;

    if (this._requestService.deliveryType$.value) {
      let type = this._requestService.deliveryType$.value;
      url =
        url +
        '/' +
        (type == 'Home'
          ? 'Delivery'
          : type == 'Store'
          ? 'Pickup'
          : type == 'EatIn'
          ? 'EatIn'
          : '');
    }
    this._requestService.getRequest(url).subscribe((res) => {
      if (res['Info'].Status == 200) {
        this.recomendedRecipies = res['RecipesData'];
        this.recomendedProducts = res['ProductsData'];
        this.recomendedProductsBackup = [];
        this.recomendedRecipiesBackup = [];
        this.recomendedRecipiesBackup = [...this.recomendedRecipies];
        this.recomendedProductsBackup = [...this.recomendedProducts];
      }
    });
  }

  openUrl(url: string) {
    const component = window.location.pathname.split('/')[2];
    if (this.simpleCheckout && component !== 'store') {
      url = 'order-checkout';
    }
    if (component == 'training') {
      url = 'checkout?training=true';
    }
    this.router.navigateByUrl(this._requestService.setUrl(url));
    this.recommendations = false;
    this.recomendedRecipies = [];
    this.recomendedProducts = [];
    this.recomendedRecipies = [...this.recomendedRecipiesBackup];
    this.recomendedProducts = [...this.recomendedProductsBackup];
  }
}
