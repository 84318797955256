import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WelcomeMessage } from 'src/Model/WebCustomer';
import { ApiService } from '../service/api.service';
import { DynamicColorsService } from '../service/dynamic-colors.service';

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss'],
})
export class WelcomeMessageComponent implements OnInit, OnDestroy {
  message: WelcomeMessage;
  sub: Subscription;

  constructor(
    private apiService: ApiService,
    public css: DynamicColorsService
  ) {}

  ngOnInit(): void {
    this.getWebCustomer();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getWebCustomer() {
    this.sub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) return;
      this.message = res.TemplateData.WelcomeMessage;
    });
  }

  close() {
    this.message.Enabled = false;
  }
}
