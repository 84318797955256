import { Injectable } from '@angular/core';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';

@Injectable({ providedIn: 'root' })
export class LoginService {
  brandFlavor: string;

  constructor(
    private _local: GetterService,
    private _requestService: RequestService
  ) {
    this.brandFlavor = window.location.pathname.split('/')[1];
  }

  getUserData() {
    let username = this._local.get('username');
    if (!username) return;
    const body = {
      BrandFlavor: this.brandFlavor,
    };

    this._requestService
      .postRequest('api/Customer/getCustomer/' + username + '/0', body)
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this._local.set('CustomerId', res['Data']['CustomerId']);
            this._local.set(
              btoa('CustomerEmail'),
              btoa(res['Data']['CustomerEmail'])
            );
            this._local.set(
              btoa('CustomerPassword'),
              btoa(res['Data']['CustomerPassword'])
            );
            this._local.set(
              'CustomerFirstName',
              res['Data']['CustomerFirstName']
            );
            this._local.set(
              'CustomerLastName',
              res['Data']['CustomerLastName']
            );
            this._local.set('CustomerMobile', res['Data']['CustomerMobile']);
            this._local.set(
              'CustomerImageUrl',
              res['Data']['CustomerImageUrl']
            );
            this._local.set(
              'FK_BrandId_Customer',
              res['Data']['FK_BrandId_Customer']
            );

            this._local.set('PromoCode', res['Data']['PromoCode']);

            return;
          }
        },
        error: () => {},
        complete: () => {},
      });
  }
}
