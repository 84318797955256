import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ErrorHandlingService } from 'src/app/service/error-handling.service';
import { PopupComponent } from './popup.component';

@NgModule({
  declarations: [PopupComponent],
  imports: [CommonModule, FormsModule, NgxSpinnerModule],
  exports: [PopupComponent],
  providers: [{ provide: ErrorHandler, useClass: ErrorHandlingService }],
})
export class PopupModule {}
