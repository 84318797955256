<div class="mainContainer">
  <div class="p-relative">
    <span class="caretBack" (click)="close()"
      ><i class="fas fa-angle-left" aria-hidden="true"></i>Back</span
    >
    <h1 class="m-0 text-center p-2">Categories</h1>
  </div>
  <div class="categories">
    <div *ngFor="let category of brand_Categories">
      <div
        class="item"
        (click)="
          currentComponent != 'store'
            ? openCat(category, $event)
            : catCliked(category)
        "
        [ngStyle]="
          selectedCatId == category.CategoryId
            ? {
                'background-color': SelectedButtonColor,
                color: SelectedButtonTextColor
              }
            : {}
        "
      >
        <p class="m-0 itemTitle">{{ category.CategoryTitle }}</p>
        <i
          class="fas fa-angle-right itemCaret"
          aria-hidden="true"
          *ngIf="category.Subcategories?.length"
        ></i>
      </div>
      <div
        *ngIf="
          selectedCatId == category.CategoryId && currentComponent != 'store'
        "
      >
        <div
          class="item ms-5"
          *ngFor="let subCat of category.Subcategories"
          [ngStyle]="
            selectedSubCat == subCat.SubcategoryId
              ? {
                  'background-color': SelectedButtonColor,
                  color: SelectedButtonTextColor
                }
              : {}
          "
          (click)="openSubCat(category, subCat, $event)"
        >
          <p class="m-0 itemTitle">{{ subCat.SubcategoryTitle }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
