import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { RequestService } from '../service/request.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { GetterService } from '../service/getter-service.service';
import { ApiService } from '../service/api.service';
import { recomendationsService } from '../recomendations/recomendations.service';
import {
  Campaigns,
  Header,
  HeaderBanners,
  HelpDeskConfig,
} from 'src/Model/WebCustomer';
import { UserService } from '../service/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  sideMenu: boolean;
  tiles: Campaigns[];
  showMobileSearch: boolean = false;
  brand_Name: string;
  branch_Logo: string;
  titlesFlag: any;
  webLink: string;
  ShowReferSection: boolean;
  brandFlavour: string;
  currentComponent: string;

  hover: string;
  distributorLogin: boolean;
  allowLogin: boolean;
  showHeaderCartIcon: boolean;
  /* template Type */
  tempalte: string;

  /*Customer*/
  customer: boolean;
  cusFirstName: string;
  cusLastName: string;

  /* Dynamic CSS */
  HeaderBackgroundColor: string;
  HeaderTextColor: string;
  HeaderHoverBackgroundColor: string;
  HeaderTextHoverColor: string;
  SiteBackgroundColor: string;
  HeaderCartColor: string;
  HeaderCartCounterColor: string;
  SelectedButtonColor: string;
  SelectedButtonTextColor: string;
  MenuWebBanner: string;
  MobileMenuWebBanner: string;
  HeaderRewardIcon = true;
  MobileHeaderBackgroundColor: string;
  BurgerMenuColor: string;
  MobileSearchColor: string;
  /* Side Categories */
  sideCat: boolean;
  /**Subscriptions */
  webcustomerSub: Subscription;
  storageChangeSub: Subscription;
  loggedInSub: Subscription;
  routerSub: Subscription;
  helpDeskConfig: HelpDeskConfig;
  buttonRadius: string;
  showSearch = true;

  HeaderBanners: HeaderBanners;

  defaultBanner: string;
  defaultMenuMobileWebBanner: string;
  headerConfig: Header;

  constructor(
    public _requestService: RequestService,
    private _router: Router,
    private _local: GetterService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private apiService: ApiService,
    private recomendedService: recomendationsService
  ) {
    this.routerSub = this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.currentComponent = event.url.split('/')[2];

        this.currentComponent = this.currentComponent.split('?')[0];

        const brandName = event.url.split('/')[1];
        this._local.set('BrandFlavor', brandName);
        if (
          this.currentComponent != 'login' &&
          this.currentComponent != 'forgot-password' &&
          this.currentComponent != 'registeruser' &&
          this.currentComponent != 'login?guest=false' &&
          this.currentComponent != 'login?training=true' &&
          this.currentComponent != 'guest?training=true' &&
          this.currentComponent != 'guest'
        ) {
          this._requestService.redirectToComponent = event.url;
        }
      }

      if (event instanceof NavigationEnd) {
        this.updateBannerImage(this.currentComponent);
      }
    });
  }

  ngOnInit() {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.currentComponent = window.location.pathname.split('/')[2];
    this.customer = this._requestService.isLoggedIn();
    this.getWebCustomer();
    this.afterLogin();

    this.storageChangeSub = this._requestService.storageChange.subscribe(() => {
      this.cd.detectChanges();
    });
  }

  userDetailsCheck() {
    this.customer = this._requestService.isLoggedIn();
    this.cusFirstName = this._local.get('CustomerFirstName');
    this.cusLastName = this._local.get('CustomerLastName');
  }

  getWebCustomer() {
    this.webcustomerSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        this.userDetailsCheck();
        return;
      }
      this.buttonRadius = res.TemplateData.DynamicStyle?.ButtonRadius;

      this.helpDeskConfig = res.TemplateData?.HelpDeskConfig;

      this.MobileHeaderBackgroundColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileHeaderBackgroundColor;

      this.BurgerMenuColor =
        res.TemplateData.DynamicStyle.PrimaryColors.BurgerMenuColor;

      this.MobileSearchColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileSearchColor;

      this.tempalte = res.TemplateData.TemplateType;

      this._local.set('TemplateType', this.tempalte);

      this.webLink = res.TemplateData.BusinessInfo.WebLink;

      this.branch_Logo = res.BrandData.BrandBranch.BrandBranchLogo;

      this.ShowReferSection = res.TemplateData.CheckoutDetail.ShowReferSection;

      this.allowLogin = res.TemplateData.CheckoutDetail?.AllowLogin;

      this.showHeaderCartIcon =
        res.TemplateData.CheckoutDetail?.ShowHeaderCartIcon;

      if (typeof res.TemplateData?.SearchEnabled != 'undefined')
        this.showSearch = res.TemplateData?.SearchEnabled;

      if (typeof res.TemplateData.BusinessInfo?.HeaderRewardIcon != 'undefined')
        this.HeaderRewardIcon = res.TemplateData.BusinessInfo.HeaderRewardIcon;

      this.defaultMenuMobileWebBanner =
        res.TemplateData?.DynamicStyle?.MobileMenuWebBanner;

      this.brand_Name = res.BrandData.BrandName;

      this.HeaderBackgroundColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderBackgroundColor;

      this.HeaderTextColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderTextColor;

      this.HeaderHoverBackgroundColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderHoverBackgroundColor;

      this.HeaderTextHoverColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderTextHoverColor;

      this.SiteBackgroundColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.SiteBackgroundColor;

      this.HeaderCartColor =
        res.TemplateData?.DynamicStyle?.TertiaryColors?.HeaderCartColor;

      this.HeaderCartCounterColor =
        res.TemplateData?.DynamicStyle?.TertiaryColors?.HeaderCartCounterColor;
      this.tiles = res.TemplateData.HomeTemplate.Campaigns;

      this.distributorLogin = res.TemplateData.CheckoutDetail?.DistributorLogin;

      this.defaultBanner = res.TemplateData?.DynamicStyle?.MenuWebBanner;

      this.HeaderBanners = res.TemplateData.DynamicStyle.HeaderBanners;

      this.headerConfig = res.TemplateData.Header;

      //Update banner Image after webcustomer response
      this.updateBannerImage(this.currentComponent);

      if (
        (!this._requestService.redirectToComponent &&
          this.currentComponent == 'login') ||
        this.currentComponent == 'forgot-password' ||
        this.currentComponent == 'registeruser' ||
        this.currentComponent == 'login?guest=false' ||
        this.currentComponent == 'login?training=true'
      ) {
        this._requestService.redirectToComponent = `${this.brandFlavour}/${res.TemplateData.RedirectionConfig.RedirectionFromCheckout}`;
      }
    });
  }

  updateBannerImage(componentName: string) {
    if (this.defaultBanner && this.defaultMenuMobileWebBanner) {
      this.MenuWebBanner = this.defaultBanner;
      this.MobileMenuWebBanner = this.defaultMenuMobileWebBanner;
      return;
    }

    if (componentName === 'menu') {
      this.MenuWebBanner = this.HeaderBanners?.Menu?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.Menu?.Mobile;
    }
    if (componentName === 'dine-in') {
      this.MenuWebBanner = this.HeaderBanners?.DineIn?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.DineIn?.Mobile;
    }
    if (componentName === 'events') {
      this.MenuWebBanner = this.HeaderBanners?.Event?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.Event?.Mobile;
    }
    if (componentName === 'reservation') {
      this.MenuWebBanner = this.HeaderBanners?.Reservations?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.Reservations?.Mobile;
    }
    if (componentName === 'join-reward' || componentName === 'my-rewards') {
      this.MenuWebBanner = this.HeaderBanners?.Rewards?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.Rewards?.Mobile;
    }
    if (componentName === 'giftcard') {
      this.MenuWebBanner = this.HeaderBanners?.GiftCard?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.GiftCard?.Mobile;
    }
    if (componentName === 'previous-order') {
      this.MenuWebBanner = this.HeaderBanners?.MyOrders?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.MyOrders?.Mobile;
    }
    if (componentName === 'pay') {
      this.MenuWebBanner = this.HeaderBanners?.PayOnline?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.PayOnline?.Mobile;
    }
    if (componentName == 'login') {
      this.MenuWebBanner = this.HeaderBanners?.Login?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.Login?.Mobile;
    }
    if (componentName == 'shop') {
      this.MenuWebBanner = this.HeaderBanners?.Shop?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.Shop?.Mobile;
    }
    if (componentName == 'online') {
      this.MenuWebBanner = this.HeaderBanners?.Online?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.Online?.Mobile;
    }
    if (componentName == 'products') {
      this.MenuWebBanner = this.HeaderBanners?.Products?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.Products?.Mobile;
    }
    if (componentName == 'favourites') {
      this.MenuWebBanner = this.HeaderBanners?.Favourites?.Desktop;
      this.MobileMenuWebBanner = this.HeaderBanners?.Favourites?.Mobile;
    }
  }

  callCheckout() {
    this.recomendedService.showRecomendations.next();
  }

  updateAccount() {
    this.openUrl('updateaccount');
  }

  signOut() {
    this.userService.logout();
  }

  redirect(link) {
    if (link.Redirection) {
      this.openUrl(link.Redirection);
      return;
    }
    location.href = link.WebLink;
  }

  openUrl(url: string) {
    this._router.navigateByUrl(this._requestService.setUrl(url));
  }

  afterLogin() {
    this.loggedInSub = this.userService.isLoggedIn$.subscribe((status) => {
      this.customer = status;
      if (status) this.userDetailsCheck();
    });
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.webcustomerSub.unsubscribe();
    this.storageChangeSub.unsubscribe();
    this.loggedInSub.unsubscribe();
    this.routerSub.unsubscribe();
  }
}
