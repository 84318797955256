import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectDeliveryTypeComponent } from './select-delivery-type.component';

@NgModule({
  declarations: [SelectDeliveryTypeComponent],
  imports: [CommonModule],
  exports: [SelectDeliveryTypeComponent],
})
export class SelectDeliveryTypeModule {}
