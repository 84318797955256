<div
  class="overlay overlayNotify"
  *ngIf="selectDeliveryType && deliveryTypes?.Enabled"
>
  <div class="popup">
    <div
      class="popup-header d-block"
      [ngStyle]="{ 'background-color': deliveryTypes?.Title?.BGColor }"
    >
      <h2
        class="text-center m-0"
        [ngStyle]="{ color: deliveryTypes?.Title?.Color }"
      >
        {{ deliveryTypes?.Title?.Text }}
      </h2>
    </div>
    <div
      class="popup-body p-3 d-flex justify-content-around"
      [ngStyle]="{ 'background-color': deliveryTypes?.BGColor }"
    >
      <div
        class="popupDelivery"
        (click)="changeDeliveryType('Home'); selectDeliveryType = false"
        *ngIf="deliveryTypes?.Delivery?.Enabled"
        [ngStyle]="{
          'background-color': deliveryTypes?.Delivery?.BGColor,
          color: deliveryTypes?.Delivery?.Color
        }"
      >
        <img [src]="deliveryTypes?.Delivery?.Image" alt="Delivery Image" />
        <h2 class="m-0">{{ deliveryTypes?.Delivery?.Text }}</h2>
        <small class="d-block" *ngIf="!DeliveryFrom && MinDeliveryTime">
          Approx {{ MinDeliveryTime }} minutes
        </small>
        <small class="d-block" *ngIf="DeliveryFrom">
          <strong>{{ DeliveryFrom }}</strong>
        </small>
      </div>

      <div
        class="popupDelivery"
        (click)="changeDeliveryType('Store'); selectDeliveryType = false"
        *ngIf="deliveryTypes?.Collection?.Enabled"
        [ngStyle]="{
          'background-color': deliveryTypes?.Collection?.BGColor,
          color: deliveryTypes?.Collection?.Color
        }"
      >
        <img [src]="deliveryTypes?.Collection?.Image" alt="Collection Image" />
        <h2 class="m-0">{{ deliveryTypes?.Collection?.Text }}</h2>
        <small class="d-block" *ngIf="!CollectionFrom && MinCollectionTime">
          Approx {{ MinCollectionTime }} minutes
        </small>
        <small class="d-block" *ngIf="CollectionFrom">
          <strong> {{ CollectionFrom }}</strong>
        </small>
      </div>

      <div
        class="popupDelivery"
        (click)="changeDeliveryType('EatIn'); selectDeliveryType = false"
        *ngIf="deliveryTypes?.EatIn?.Enabled"
        [ngStyle]="{
          'background-color': deliveryTypes?.EatIn?.BGColor,
          color: deliveryTypes?.EatIn?.Color
        }"
      >
        <img [src]="deliveryTypes?.EatIn?.Image" alt="Collection Image" />
        <h2 class="m-0">{{ deliveryTypes?.EatIn?.Text }}</h2>
        <small class="d-block" *ngIf="!EatInFrom && MinEatInTime">
          Approx {{ MinEatInTime }} minutes
        </small>
        <small class="d-block" *ngIf="EatInFrom">
          <strong> {{ EatInFrom }}</strong>
        </small>
      </div>
    </div>
  </div>
</div>
