import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestService } from '../service/request.service';
import { GetterService } from '../service/getter-service.service';
import { ApiService } from '../service/api.service';
import { popupService } from '../popup/popup.service';
import { Subscription } from 'rxjs';
import { Recipe } from 'src/Model/Product-Interfaces';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  notfound: any = 0;
  products: any;
  isLoading: boolean;
  recipeData: Recipe[] = [];
  productData: any = [];

  focus: boolean;
  searchResult: string;
  searchResSub: Subscription;
  webCusSub: Subscription;
  popupServiceSub: Subscription;
  vehiclesBrand: boolean;
  brandFlavor: string;
  partsBrand: any;

  constructor(
    private _requestService: RequestService,
    private _local: GetterService,
    private apiService: ApiService,
    private popupService: popupService
  ) {
    this.brandFlavor = window.location.pathname.split('/')[1];
  }

  ngOnInit() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }

      this.vehiclesBrand = res.TemplateData?.VehiclesBrand;
      this.partsBrand = res.TemplateData?.PartsBrand;
    });
    this.popupServiceSub = this.popupService.addedToCartSuccessful.subscribe(
      (data) => {
        this.productData.forEach((prod) => {
          if (prod.ProductId == data.ProductId) {
            prod.ProductQuantity = data.ProductQuantity;
          }
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
    this.popupServiceSub.unsubscribe();
  }

  onsubmit() {}

  searchProducts(inputEvent: Event) {
    if (this.searchResSub) {
      this.searchResSub.unsubscribe();
    }
    const value = (inputEvent.target as HTMLInputElement).value;
    if (value.length < 4) {
      this.recipeData = [];
      this.productData = [];
      this.isLoading = false;
      this.searchResult = null;
      return;
    }

    this.isLoading = true;
    let type:string = this._requestService.deliveryType$.value;

    if (type) {
      if (type == 'Home') {
        type = 'Delivery';
      } else if (type == 'Store') {
        type = 'Pickup';
      }
    }

    let body = {
      BrandFlavor: this.brandFlavor,
      SearchKey: value,
      Offset: 0,
      Limit: 40,
      OrderOption: type,
      OnlineOrderingEnabled: true,
    };

    let link = '';
    if (this.vehiclesBrand) {
      link = 'api/Product/searchVehicle';
    } else if (this.partsBrand) {
      link = 'api/Product/SearchDetailProduct';
    } else {
      link = 'api/Product/search';
    }
    this.searchResSub = this._requestService
      .postRequest(link, JSON.stringify(body))
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res.Info.Status == 200) {
            this.searchResult = null;
            this.notfound = 0;

            this.recipeData = res['Data']?.RecipeData
              ? res['Data']?.RecipeData
              : [];

            this.productData = res['Data'].ProductData
              ? res['Data'].ProductData
              : [];

            const filteredRecipes = this.recipeData.filter(
              (recipe) => recipe.ForCollection || recipe.ForDelivery
            );

            this.recipeData = filteredRecipes;

            let cart = JSON.parse(this._local.get('cartProductItems'));
            cart?.forEach((product) => {
              this.productData.forEach((item) => {
                if (item.ProductId == product.ProductId) {
                  item.ProductQuantity = product.ProductQuantity;
                }
              });
            });
          } else {
            this.notfound = 1;
            this.recipeData = [];
            this.productData = [];
            this.searchResult = 'No item found';
          }
        },
        error: () => {
          this.notfound = 1;
          this.isLoading = false;
          this.recipeData = [];
          this.productData = [];
          this.searchResult = 'Something went wrong';
        },
      });
  }
  onBlur() {
    this.focus = false;
  }
  onFocus() {
    this.focus = true;
  }
}
