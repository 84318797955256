import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { CartRecipies, CartProdut } from "../../Model/Checkout-Interfaces";
export interface popupData {
    /* isDiscountedItem: boolean, */
    MenuBuilder: boolean,
    dineIn: boolean,
    eatIn: boolean,
    filteredProduct: any,
    filteredRecipe: any,
    renderedExtraList: any[],
    renderExtralistChooseOnes: number,
    openForNonExtras: boolean,
    quantity?: number,
    mergePopups?:boolean,
    renderedMenuBuilder?
}
@Injectable({
    providedIn: 'root'
})
export class popupService {
    popUpData = new Subject<popupData>();
    addedToCartSuccessful: Subject<any> = new Subject<CartRecipies | CartProdut>();
    constructor() { }
}