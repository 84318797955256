<div class="container-fluid p-0" *ngIf="!showDetails">
  <div class="row">
    <div class="col-md-4 col-sm-6 col-12 p-1" *ngFor="let product of products">
      <div
        class="bg-white d-flex justify-content-between flex-column h-100 br-10px"
      >
        <div>
          <img
            *ngIf="product.ProductImageUrl"
            [src]="product.ProductImageUrl"
            [alt]="product.ProductTitle"
            class="w-100 br-10px"
          />
        </div>
        <div class="d-flex justify-content-between flex-column">
          <p class="text-muted p-2">{{ product.ProductTitle }}</p>
          <div>
            <span>
              <p class="fs-5 m-0" *ngIf="product.ProductOldPrice">
                RRP
                {{
                  product.ProductOldPrice
                    | currency : "GBP" : "symbol" : "1.1-2"
                }}
              </p>
            </span>
          </div>

          <div class="d-flex justify-content-between align-items-center p-2">
            <span
              class="cursor-pointer m-1 p-1 fs-3"
              (click)="getProductModal(product)"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </span>

            <span
              class="cartImg p-2 br-10px cursor-pointer"
              (click)="showProductDetails(product)"
            >
              <img
                src="../../../../assets/img/info-Icon.png"
                alt="info"
                class="w-100 br-10px"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="showDetails">
  <div class="" *ngFor="let product of products">
    <h2 class="m-0">{{ product.ProductTitle }}</h2>
    <p class="fs-5 m-0" *ngIf="product.ProductOldPrice">
      RRP
      {{ product.ProductOldPrice | currency : "GBP" : "symbol" : "1.1-2" }}
    </p>
    <table align="center">
      <tbody>
        <tr>
          <th>
            <span><span>Model</span></span>
          </th>
          <th>
            <span><span>Engine</span></span>
          </th>
          <th>
            <span><span>Year</span></span>
          </th>
        </tr>
        <tr>
          <td>
            <span>{{ product.ProductDetailPartModel }}</span>
          </td>
          <td>
            <span>{{ product.ProductDetailEngine }}</span>
          </td>
          <td>
            <span
              >{{ product.ProductDetailFromYear }}&#8211;{{
                product.ProductDetailToYear
              }}</span
            >
          </td>
        </tr>
        <tr>
          <th>
            <span><span>Code</span></span>
          </th>
          <th>
            <span><span>RRP (exc VAT)</span></span>
          </th>
          <th><span>More Details</span></th>
        </tr>
        <tr>
          <td>
            <span>{{ product.ProductDetailCode }}</span>
          </td>
          <td>
            <span
              ><em>{{
                product.ProductOldPrice | currency : "GBP"
              }}</em></span
            >
          </td>
          <td>
            <span
              ><a
                [href]="product.ProductDetailExternalLink"
                target="_blank"
                ><span>Vehicle Info</span></a
              ></span
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2 d-flex justify-content-end">
      <button class="btn bg-red br-40px" (click)="getProductModal(product)">
        Add to whislist
      </button>
    </div>
    <hr />
  </div>
</ng-container>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>

<div
  class="overlay d-flex justify-content-center align-items-center"
  *ngIf="productDetails"
>
  <div class="content bg-white br-40px overflow-hidden m-1">
    <div>
      <h1 class="text-center bg-light">Details</h1>
    </div>
    <table align="center">
      <tbody>
        <tr>
          <th>
            <span><span>Model</span></span>
          </th>
          <th>
            <span><span>Engine</span></span>
          </th>
          <th>
            <span><span>Year</span></span>
          </th>
        </tr>
        <tr>
          <td>
            <span>{{ productDetails.ProductDetailPartModel }}</span>
          </td>
          <td>
            <span>{{ productDetails.ProductDetailEngine }}</span>
          </td>
          <td>
            <span
              >{{ productDetails.ProductDetailFromYear }}&#8211;{{
                productDetails.ProductDetailToYear
              }}</span
            >
          </td>
        </tr>
        <tr>
          <th>
            <span><span>Code</span></span>
          </th>
          <th>
            <span><span>RRP (exc VAT)</span></span>
          </th>
          <th><span>More Details</span></th>
        </tr>
        <tr>
          <td>
            <span>{{ productDetails.ProductDetailCode }}</span>
          </td>
          <td>
            <span
              ><em>{{
                productDetails.ProductOldPrice | currency : "GBP"
              }}</em></span
            >
          </td>
          <td>
            <span
              ><a
                [href]="productDetails.ProductDetailExternalLink"
                target="_blank"
                ><span>Vehicle Info</span></a
              ></span
            >
          </td>
        </tr>
      </tbody>
    </table>

    <p class="text-center text-danger">
      <strong>The price is RRP and excludes VAT.</strong>
    </p>

    <button
      class="btn btn-danger mt-3 w-100"
      (click)="productDetails = undefined"
    >
      Close
    </button>
  </div>
</div>
