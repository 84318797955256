import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/Model/Product-Interfaces';
import { ProductCardService } from '../product-card.service';

@Component({
  selector: 'app-store-product',
  templateUrl: './store-product.component.html',
  styleUrls: ['./store-product.component.scss'],
})
export class StoreProductComponent implements OnInit {
  @Input() products: Product[] = [];
  @Input() showDetails: boolean = false;
  productDetails: Product;
  constructor(private productCardService: ProductCardService) {}

  ngOnInit(): void {}

  showProductDetails(product: Product) {
    this.productDetails = product;
  }

  getProductModal(product: Product) {
    this.productCardService.getProductModal(product);
  }
}
