import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { SearchModule } from '../search/search.module';
import { SideDrawerModule } from '../side-drawer/side-drawer.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, SearchModule, SideDrawerModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
