<div class="overlay" *ngIf="message && message.Enabled">
  <div class="main-content">
    <div
      class="position-relative"
      [ngStyle]="{
        'background-color': css.SelectedButtonColor,
        color: css.SelectedButtonTextColor
      }"
    >
      <h1 class="text-center m-0 p-2">
        {{ message?.Heading }}
      </h1>
      <button
        class="btn position-absolute times"
        [ngStyle]="{
          color: css.SelectedButtonTextColor
        }"
        (click)="close()"
      >
        <span class="fa fa-times"></span>
      </button>
    </div>

    <div class="content">
      <div class="d-flex justify-content-center">
        <img [src]="message?.Image" alt="image" *ngIf="message?.Image" class="rounded-0"/>
      </div>

      <h2 class="text-center" *ngIf="message?.Title">{{ message?.Title }}</h2>
      <p class="text-center" *ngIf="message?.Description">
        {{ message?.Description }}
      </p>
    </div>

    <button
      class="btn w-100 rounded-0"
      [ngStyle]="{
        'background-color': css.SelectedButtonColor,
        color: css.SelectedButtonTextColor
      }"
      (click)="close()"
    >
      Close
    </button>
  </div>
</div>
