import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { GetterService } from 'src/app/service/getter-service.service';
import { RequestService } from 'src/app/service/request.service';
import { DeliveryTypes } from 'src/Model/WebCustomer';
import { SelectDeliveryType } from './select-delivery-type.service';

@Component({
  selector: 'app-select-delivery-type',
  templateUrl: './select-delivery-type.component.html',
  styleUrls: ['./select-delivery-type.component.scss'],
})
export class SelectDeliveryTypeComponent implements OnInit, OnDestroy {
  selectDeliveryType: boolean;
  DeliveryFrom;
  MinDeliveryTime;
  CollectionFrom;
  MinCollectionTime;
  popupSub: Subscription;
  brandConfigSub: Subscription;
  deliveryTypes: DeliveryTypes;
  EatInFrom: string;
  MinEatInTime: number;
 
  constructor(
    private _local: GetterService,
    private _requestService: RequestService,
    private apiService: ApiService,
    private selectService: SelectDeliveryType
  ) {}

  ngOnInit(): void {
    this.getWebCustomer();
    this.popupSub = this.selectService.showPopup.subscribe(() => {
      this.selectDeliveryType = true;
    });
    this.brandConfigSub = this.apiService.getbrandConfig$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.MinCollectionTime = res.MinCollectionTime;
      this.MinDeliveryTime = res.MinDeliveryTime;
      this.DeliveryFrom = res.DeliveryFrom;
      this.CollectionFrom = res.CollectionFrom;
      this.EatInFrom = res.EatInFrom;
      this.MinEatInTime = res.MinEatInTime;
    });
  }

  getWebCustomer() {
    this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) return;
  
      this.deliveryTypes = res.TemplateData.DeliveryTypes;
    });
  }

  ngOnDestroy(): void {
    this.brandConfigSub.unsubscribe();
    this.popupSub.unsubscribe();
  }

  changeDeliveryType(type: "Home" | "Store" | "EatIn") {
    this.selectDeliveryType = false;
    this._local.set('deliveryType', type);
    this._requestService.deliveryType$.next(type);
  }
}
