<div
  class="overlay d-flex justify-content-center align-items-center"
  *ngIf="!online"
>
  <div class="content bg-white br-40px overflow-hidden">
    <h2 class="text-center p-2 mt-3">
      Seems like you have lost the internet connection! For the Application to
      run smoothly you need to have internet connection
    </h2>
    <!-- <button class="btn btn-success w-100" (click)="referesh()">Referesh</button> -->
  </div>
</div>
