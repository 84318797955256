import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './Guards/auth.guard';
import { PreviousOrderGuard } from './Guards/previous-order.guard';
import { MyRewardGuard } from './Guards/my-reward.guard';
import { ReferalGuard } from './Guards/referal.guard';
import { payGuard } from './Guards/pay.guard';
import { GuestGuard } from './Guards/guest.guard';
import { LoginGuard } from './Guards/login.guard';

const routes: Routes = [
  { path: '', redirectTo: ':brand_name/home', pathMatch: 'full' },

  //For Crown Epoxy
  {
    path: ':brand_name/products',
    loadChildren: () =>
      import('./products/products.module').then((m) => m.ProductsModule),
  },

  {
    path: ':brand_name/events',
    loadChildren: () =>
      import('./events/events.module').then((m) => m.EventsModule),
  },

  {
    path: ':brand_name/login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },

  {
    path: ':brand_name/menu',
    loadChildren: () => import('./menu/menu.module').then((m) => m.MenuModule),
  },

  {
    path: ':brand_name/app-menu',
    loadChildren: () => import('./menu/menu.module').then((m) => m.MenuModule),
  },

  {
    path: ':brand_name/dine-in',
    loadChildren: () =>
      import('./dineIn/dineIn.module').then((m) => m.DineInModule),
  },

  {
    path: ':brand_name/join-reward',
    loadChildren: () =>
      import('./reward/reward.module').then((m) => m.RewardModule),
  },

  {
    path: ':brand_name/postcode-iframe',
    loadChildren: () =>
      import('./postcode-iframe/postcode-iframe.module').then(
        (m) => m.PostcodeIframeModule
      ),
  },

  {
    path: ':brand_name/reservation',
    loadChildren: () =>
      import('./reservation/reservation.module').then(
        (m) => m.ReservationModule
      ),
  },

  {
    path: ':brand_name/reserve-iframe',
    loadChildren: () =>
      import('./reservation/reservation.module').then(
        (m) => m.ReservationModule
      ),
  },

  {
    path: ':brand_name/updateaccount',
    canActivate: [PreviousOrderGuard],
    loadChildren: () =>
      import('./update-account/update-account.module').then(
        (m) => m.UpdateAccountModule
      ),
  },
  {
    path: ':brand_name/delete-account',
    loadChildren: () =>
      import('./delete-account/delete-account.module').then(
        (m) => m.DeleteAccountModule
      ),
  },
  {
    path: ':brand_name/forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },

  {
    path: ':brand_name/my-rewards',
    canActivate: [MyRewardGuard],
    loadChildren: () =>
      import('./my-rewards/my-rewards.module').then((m) => m.MyRewardsModule),
  },

  {
    path: ':brand_name/previous-order',
    canActivate: [PreviousOrderGuard],
    loadChildren: () =>
      import('./previous-order/previous-order.module').then(
        (m) => m.PreviousOrderModule
      ),
  },
  {
    path: ':brand_name/checkout',
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutModule),
    canActivate: [AuthGuard],
  },

  {
    path: ':brand_name/order-checkout',
    loadChildren: () =>
      import('./order-checkout/order-checkout.module').then(
        (m) => m.OrderCheckoutModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: ':brand_name/terms-and-conditions',
    loadChildren: () =>
      import('./terms/terms.module').then((m) => m.TermsModule),
  },

  {
    path: ':brand_name/privacy-policy',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },

  {
    path: ':brand_name/guest',
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutModule),
    canActivate: [GuestGuard],
  },

  {
    path: ':brand_name/pay',
    loadChildren: () => import('./pay/pay.module').then((m) => m.PayModule),
    canActivate: [payGuard],
  },

  {
    path: ':brand_name/giftcard',
    loadChildren: () =>
      import('./send-giftcard/send-giftcard.module').then(
        (m) => m.SendGiftcardModule
      ),
    canActivate: [MyRewardGuard],
  },

  {
    path: ':brand_name/branches',
    loadChildren: () =>
      import('./branches/branches.module').then((m) => m.BranchesModule),
  },

  {
    path: ':brand_name/online',
    loadChildren: () =>
      import('src/app/online/online.module').then((m) => m.OnlineModule),
  },

  {
    path: ':brand_name/shop',
    loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule),
  },

  {
    path: ':brand_name/store',
    loadChildren: () =>
      import('src/app/store/store.module').then((m) => m.StoreModule),
  },

  {
    path: ':brand_name/distributors',
    loadChildren: () =>
      import('src/app/store/store.module').then((m) => m.StoreModule),
  },

  {
    path: ':brand_name/category-products/:catID',
    loadChildren: () =>
      import('./category-products/category-products.module').then(
        (m) => m.CategoryProductsModule
      ),
  },

  {
    path: ':brand_name/refer',
    loadChildren: () =>
      import('./referal/referal.module').then((m) => m.ReferalModule),
    canActivate: [ReferalGuard],
  },

  {
    path: ':brand_name/favourites',
    loadChildren: () =>
      import('./favourites/favourites.module').then((m) => m.FavouritesModule),
    canActivate: [LoginGuard],
  },

  {
    path: ':brand_name/likeAndShare',
    loadChildren: () => import('./like/like.module').then((m) => m.LikeModule),
  },

  {
    path: ':brand_name/stock-list',
    loadChildren: () =>
      import('src/app/car-listings/list/car-listing.module').then(
        (m) => m.CarlistingModule
      ),
  },

  {
    path: ':brand_name/sold-cars',
    loadChildren: () =>
      import('src/app/car-listings/list/car-listing.module').then(
        (m) => m.CarlistingModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
