import { ErrorHandler, Injectable } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService extends ErrorHandler {
  error: string;
  component: string;
  deviceInfo: DeviceInfo;
  constructor(
    private _requestService: RequestService,
    private deviceDetector: DeviceDetectorService
  ) {
    super();
    this.deviceInfo = this.deviceDetector.getDeviceInfo();
  }
  override handleError(error: any) {
    if (this.error == `${error}` + this.component) return;

    this.component = ` -- Component -- ${
      window.location.pathname.split('/')[2]
    }`;
    this.error = `${error}` + this.component;

    let body = {
      BrandFlavor: window.location.pathname.split('/')[1],
      Message:
        `${this.error} ` + `Device Info--` + JSON.stringify(this.deviceInfo),
    };

    this._requestService
      .postRequest('api/Logs/CreateCustomerPortalLogs', JSON.stringify(body))
      .subscribe();
    super.handleError(error);
  }
}
