import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SearchedCarModule } from '../car-listings/searched-cars/searched-car.module';
import { ResponsiveGridStyleModule } from '../cards/product/responsive-grid-style/responsive-grid-style.module';
import { StoreProductModule } from '../cards/product/store-product/store-products.module';
import { RecipeModule } from '../cards/recipe/recipe.module';
import { ErrorHandlingService } from '../service/error-handling.service';
import { SearchComponent } from './search.component';

@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    RecipeModule,
    StoreProductModule,
    ResponsiveGridStyleModule,
    SearchedCarModule,
  ],
  exports: [SearchComponent],
  providers: [{ provide: ErrorHandler, useClass: ErrorHandlingService }],
})
export class SearchModule {}
