import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecomendationsComponent } from './recomendations.component';
import { RecipeModule } from '../cards/recipe/recipe.module';
import { ResponsiveGridStyleModule } from '../cards/product/responsive-grid-style/responsive-grid-style.module';

@NgModule({
  declarations: [RecomendationsComponent],
  imports: [CommonModule, RecipeModule, ResponsiveGridStyleModule],
  exports: [RecomendationsComponent],
})
export class RecomendationsModule {}
