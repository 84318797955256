import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';

@Injectable({
  providedIn: 'root',
})
export class favouritesService {
  private favouritesSubject = new BehaviorSubject<any[]>([]);
  private spinnerSubject = new BehaviorSubject<boolean>(false);
  private cartTotalSubject = new BehaviorSubject<any>(0);
  favourites$: Observable<any[]> = this.favouritesSubject.asObservable();
  cartTotal$: Observable<any> = this.cartTotalSubject.asObservable();
  spinner$: Observable<any> = this.spinnerSubject.asObservable();
  MenuBuilder: boolean = false;

  constructor(
    private _requestService: RequestService,
    private _local: GetterService
  ) {
    this.getCartTotal();
    if (this._local.get('access_token')) {
      this.getFavourites();
    }
  }

  loadFavs() {
    this.getFavourites();
  }

  getFavourites() {
    this.spinnerSubject.next(true);
    let customerId = this._local.get('CustomerId');

    this._requestService
      .getRequest(`api/CustomerFavourites/getItems/${customerId}`)
      .pipe(
        map((res) => {
          this.spinnerSubject.next(false);
          let products;
          if (res['Info'].Status == 200) {
            products = res['Products'];
            products.forEach((product) => {
              product.ProductQuantity = 0;
            });
            let cart = JSON.parse(this._local.get('cartProductItems'));
            cart?.forEach((product) => {
              products.forEach((item) => {
                if (item.ProductId == product.ProductId) {
                  item.ProductQuantity = product.ProductQuantity;
                }
              });
            });
          }
          return products;
        })
      )
      .subscribe((res) => {
        if (res?.length) {
          this.favouritesSubject.next(res);
        }
      });
  }

  deleteFavourite(product): Observable<any> {
    let body = {
      FK_CustomerId: this._local.get('CustomerId'),
      FK_RecipeId: null,
      FK_ProductId: product.ProductId,
      Status: false,
    };

    return this._requestService
      .postRequest('api/CustomerFavourites/updateItem', JSON.stringify(body))
      .pipe(
        tap((res) => {
          if (res['Info'].Status == 200) {
            let fav = this.favouritesSubject.getValue();
            const index = fav.findIndex(
              (items) => items.ProductId == product.ProductId
            );
            fav.forEach((item) => {
              if (item.ProductId == product.ProductId) {
                fav.splice(index, 1);
              }
            });
            this.favouritesSubject.next(fav);
          }
        })
      );
  }

  addedToCart(product) {
    let products = this.favouritesSubject.getValue();
    products?.forEach((prod) => {
      if (prod.ProductId == product.ProductId) {
        prod.ProductQuantity = product.ProductQuantity;
      }
    });
    this.getCartTotal();
  }

  syncWithSideCart(product) {
    let products = this.favouritesSubject.getValue();
    this.getCartTotal();
    if (product.flag == 'removed') {
      products?.forEach((item) => {
        if (item.ProductId == product.item.ProductId) {
          item.ProductQuantity = 0;
        }
      });
      return;
    }
    if (product.flag == '-' && product.item.ProductQuantity == 0) {
      products?.forEach((item) => {
        if (item.ProductId == product.item.ProductId) {
          item.ProductQuantity = 0;
        }
      });
      return;
    }

    products?.forEach((item) => {
      if (item.ProductId == product.item.ProductId) {
        item.ProductQuantity = product.item.ProductQuantity;
      }
    });
  }

  getCartTotal() {
    let total = 0;
    let cart = JSON.parse(this._local.get('cartProductItems'));
    cart?.forEach((product) => {
      total += product.ProductTotal;
    });
    this.cartTotalSubject.next(total);
  }

  addFavouriteToList(product) {
    let favs = this.favouritesSubject.getValue();
    favs.push(product);
    this.favouritesSubject.next(favs);
  }

  removeFavouriteFromList(product) {
    let favs = this.favouritesSubject.getValue();
    const index = favs.findIndex((item) => product.ProductId == item.ProductId);
    favs.splice(index, 1);
    this.favouritesSubject.next(favs);
  }

}
