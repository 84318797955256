import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PopupModule } from 'src/app/popup/popup.module';
import { ErrorHandlingService } from 'src/app/service/error-handling.service';

import { RecipeComponent } from './recipe.component';
@NgModule({
  declarations: [RecipeComponent],
  imports: [CommonModule, FormsModule, NgxSpinnerModule, PopupModule],
  exports: [RecipeComponent],
  providers: [{ provide: ErrorHandler, useClass: ErrorHandlingService }],
})
export class RecipeModule {}
