import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-progress',
  template: `<div
    class="progress"
    *ngIf="showProgress"
    style="height: '5px'; position: fixed; top: 0; z-index: 10000; width: 100%"
  >
    <div
      class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
      role="progressbar"
      [style]="{ width: progress + '%', 'background-color': '#009582' }"
    ></div>
  </div>`,
})

export class ProgressComponent {
  showProgress: boolean;
  progress: number;
  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showProgress = true;
        this.progress = 40;
      }

      this.progress += 4.76;

      if (event instanceof NavigationEnd) {
        this.showProgress = false;
        this.progress = 100;
      }
    });
  }
}
