import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InquiryPopupComponent } from './inquiry-popup.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [InquiryPopupComponent],
  imports: [CommonModule, FormsModule],
  exports: [InquiryPopupComponent],
})
export class InquiryPopupModule {}
