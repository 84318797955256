import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicColorsService {
  ItemBackgroundColor: string;
  ItemTitleColor: string;
  SelectedButtonTextColor: string;
  SelectedButtonColor: string;
  MobileHeaderBackgroundColor: string;
  BurgerMenuColor: string;
  MobileSearchColor: string;
  HeaderBackgroundColor: string;
  HeaderTextColor: string;
  HeaderHoverBackgroundColor: string;
  HeaderTextHoverColor: string;
  SiteBackgroundColor: string;
  HeaderCartColor: string;
  HeaderCartCounterColor: string;
  AllergensTextColor: string;
  AllergensTopBorderColor: string;
  AllergensTopTextColor: any;
  buttonRadius: string;

  constructor(private apiService: ApiService) {
    this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) return;

      const styles = res.TemplateData.DynamicStyle;

      this.AllergensTopTextColor =
        styles.TertiaryColors?.AllergensTopTextColor;

      this.AllergensTopBorderColor =
        styles.TertiaryColors?.AllergensTopBorderColor;

      this.AllergensTextColor =
        styles.TertiaryColors?.AllergensBottomTextColor;

      this.ItemBackgroundColor = styles.SecondaryColors?.ItemBackgroundColor;

      this.ItemTitleColor = styles.SecondaryColors?.ItemTitleColor;

      this.SelectedButtonTextColor =
        styles.SecondaryColors.SelectedButtonTextColor;

      this.SelectedButtonColor = styles.SecondaryColors.SelectedButtonColor;

      this.MobileHeaderBackgroundColor =
        styles.PrimaryColors.MobileHeaderBackgroundColor;

      this.BurgerMenuColor = styles.PrimaryColors.BurgerMenuColor;

      this.MobileSearchColor = styles.PrimaryColors.MobileSearchColor;

      this.HeaderBackgroundColor = styles.PrimaryColors?.HeaderBackgroundColor;

      this.HeaderTextColor = styles.PrimaryColors?.HeaderTextColor;

      this.HeaderHoverBackgroundColor =
        styles.PrimaryColors?.HeaderHoverBackgroundColor;

      this.HeaderTextHoverColor = styles.PrimaryColors?.HeaderTextHoverColor;

      this.SiteBackgroundColor = styles.PrimaryColors?.SiteBackgroundColor;

      this.HeaderCartColor = styles.TertiaryColors?.HeaderCartColor;

      this.buttonRadius = res.TemplateData.DynamicStyle?.ButtonRadius;
      
      this.HeaderCartCounterColor =
        styles.TertiaryColors?.HeaderCartCounterColor;
    });
  }
}
