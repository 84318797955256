<app-progress></app-progress>
<div class="mainDiv">
  <head>
    <title>{{ brand_Name }}</title>
  </head>
  <app-header *ngIf="isHeaderVisible"></app-header>
  <router-outlet></router-outlet>
</div>

<div *ngIf="whatsappno && isWhatsAppVisible" class="d-block d-md-none">
  <button id="myBtn" (click)="openMobileWhatsApp()">
    <img src="../assets/img/whatsapp.png" />
  </button>
</div>

<div *ngIf="whatsappno && isWhatsAppVisible" class="d-none d-md-block">
  <button id="myBtn" (click)="openWhatsApp()">
    <img src="../assets/img/whatsapp.png" />
  </button>
</div>

<app-allergens-footer *ngIf="isAllergenseFooterVisible"></app-allergens-footer>
<app-new-footer *ngIf="isFooterVisible"></app-new-footer>

<div class="bottomMenuContainer" *ngIf="bottomMenu && isBottomMenuVisible">
  <app-bottom-menu></app-bottom-menu>
</div>

<!--Popups-->
<app-popup></app-popup>
<app-recomendations></app-recomendations>
<app-select-delivery-type></app-select-delivery-type>
<app-inquiry-popup></app-inquiry-popup>

<app-welcome-message></app-welcome-message>

<app-connection-check></app-connection-check>
