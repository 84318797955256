<div class="container-fluid">
  <div class="p-relative">
    <div
      class="inputPosition"
      [ngClass]="{
        'z-index-500':
          (recipeData?.length || productData?.length || notfound) &&
          focus &&
          searchInput.value
      }"
    >
      <div
        class="br-40px border border-1 d-flex justify-content-center align-items-center bg-white"
      >
        <span class="ms-1">
          <i class="fa fa-search text-secondary" aria-hidden="true"></i>
        </span>
        <input
          type="search"
          class="searchInput form-control border-0 shadow-none w-93"
          name="search"
          id="search"
          (input)="searchProducts($event)"
          ngModel
          #searchInput="ngModel"
          (focus)="onFocus()"
        />
      </div>
      <div
        class="spinner-border spinner-border-sm spinner"
        *ngIf="isLoading"
      ></div>
    </div>
    <div
      class="resultsBox mt-1"
      *ngIf="
        (recipeData?.length || productData?.length || notfound) &&
        focus &&
        searchInput.value
      "
    >
      <p class="text-danger text-center" *ngIf="searchResult">
        {{ searchResult }}
      </p>
      <app-recipe [recipes]="recipeData"></app-recipe>

      <div *ngIf="vehiclesBrand">
        <app-car-listing
          [product]="product"
          *ngFor="let product of productData"
        ></app-car-listing>
      </div>

      <div *ngIf="partsBrand">
        <app-store-product
          [products]="productData"
          [showDetails]="true"
        ></app-store-product>
      </div>

      <div *ngIf="!vehiclesBrand && !partsBrand">
        <app-responsive-grid-style
          [products]="productData"
        ></app-responsive-grid-style>
      </div>
    </div>
    <div
      class="overlay"
      *ngIf="
        (recipeData?.length || productData?.length || notfound) &&
        focus &&
        searchInput.value
      "
      (click)="onBlur()"
    ></div>
  </div>
</div>
