import { HttpHeaders } from '@angular/common/http';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { RequestService } from '../service/request.service';
import { GetterService } from '../service/getter-service.service';
import { ApiService } from '../service/api.service';
import { Subscription } from 'rxjs';
import { Category, SubCategory } from 'src/Model/Product-Interfaces';
import { ActivatedRoute } from '@angular/router';
export interface SideMenuData {
  cat: Category;
  subCat: SubCategory;
}
@Component({
  selector: 'app-side-drawer',
  templateUrl: './side-drawer.component.html',
  styleUrls: ['./side-drawer.component.scss'],
})
export class SideDrawerComponent implements OnInit, OnDestroy {
  selectedSubCat: number;
  brandFlavour: string;
  brand_Categories;
  selectedCatId: number;

  /* Dynamic styles */
  SelectedButtonColor = '#B8860B';
  SelectedButtonTextColor: any = '#fff';

  @Output() closeSideCat = new EventEmitter<boolean>();
  template: string;
  subs: Subscription[] = [];
  currentComponent: string;
  catId: number;
  constructor(
    private _requestService: RequestService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.currentComponent = window.location.pathname.split('/')[2];
    this.currentComponent = this.currentComponent.split('?')[0];
    this.brandFlavour = window.location.pathname.split('/')[1];

    this.getWebCustomer();
    this.resetCategories();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  resetCategories() {
    const sub = this._requestService.resetSideCats.subscribe(() => {
      this.catCliked(this.brand_Categories[0]);
    });
    this.subs.push(sub);
  }

  getWebCustomer() {
    const sub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.template = res.TemplateData.TemplateType;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;

      this.catId = +this.route.snapshot.queryParams['catId'];
      if (this.template == 'Stellar') {
        this.getCategories();
      }
    });
    this.subs.push(sub);
  }

  getCategories() {
    const body = { OnlineOrderingEnabledCategory: true };
    this._requestService
      .postRequest(
        `api/Category/v1/getCategories/${this.brandFlavour}/Product`,
        body
      )
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this.brand_Categories = res['Data'];

            if (this.currentComponent != 'store') {
              this.openCat(this.brand_Categories[0]);
              return;
            }
            if (this.currentComponent == 'store') {
           
              if (this.catId) {
                const cat = this.brand_Categories.find(
                  (cat) => cat.CategoryId == this.catId
                );
             
                this.catCliked(cat);
                this.catId = 0;
                return;
              }

              this.catCliked(this.brand_Categories[0]);
            }
          }
        },
      });
  }

  catCliked(cat) {
    this.selectedCatId = cat.CategoryId;
    var data = {
      cat: cat,
      subCat: null,
    };
    this._requestService.sideCatClicked.next(data);
    this.closeSideCat.emit(false);
  }

  openCat(cat, event?) {
    event?.stopPropagation();
    var data = {
      cat: cat,
      subCat: null,
    };

    this._requestService.sideCatChanged.next(data);
    if (!cat.Subcategories?.length) {
      this.closeSideCat.emit(false);
    }
    this.selectedCatId = cat.CategoryId;
    this.selectedSubCat = cat?.Subcategories[0]?.SubcategoryId;
  }

  openSubCat(cat, subCat, event?) {
    event?.stopPropagation();
    var data = {
      cat: cat,
      subCat: subCat,
    };
    this.selectedSubCat = subCat.SubcategoryId;
    this._requestService.sideCatChanged.next(data);
    this.closeSideCat.emit(false);
  }

  close() {}
}
