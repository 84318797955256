import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  ChangeDetectorRef,
  EventEmitter,
} from '@angular/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
import { ApiService } from '../service/api.service';
import { recomendationsService } from '../recomendations/recomendations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CartRecipies } from '../../Model/Checkout-Interfaces';
import {
  StopOnlineOrders,
  StopOnlineOrdersMessage,
} from 'src/Model/WebCustomer';
declare let fbq: any;
@Component({
  selector: 'app-side-cart',
  templateUrl: './side-cart.component.html',
  styleUrls: ['./side-cart.component.scss'],
})
export class SideCartComponent implements OnInit, OnDestroy {
  cartProductItems: any[];
  cartRecipeItems: CartRecipies[];
  cartRecipeItemsextra = [];
  SelectedButtonColor = '#B8860B';
  SelectedButtonTextColor;
  training: boolean;
  sideCartButtonText: string;

  @Output() updateCart = new Subject<any>();
  @Output() updateKgList = new Subject<any>();
  @Output() checkout = new EventEmitter();
  brandFlavour: string;
  PopupOnKgItems: boolean;

  hotCatogries: any[] = [];
  windowWidth;

  total: number;
  sideCartHeaderText: string;
  emptyCartMessage: string;
  buttonRadius: string;

  stopOnlineOrders: StopOnlineOrders = STOPONLINEORDERS;
  stopOnlineOrdersMessage: StopOnlineOrdersMessage | undefined;
  StopOnlineDeliveryOrders: boolean;
  StopOnlineCollectionOrders: boolean;
  StopOnlineEatInOrders: boolean;

  subs: Subscription[] = [];
  constructor(
    private _local: GetterService,
    public _requestService: RequestService,
    private _ref: ChangeDetectorRef,
    private apiService: ApiService,
    private recomendationsService: recomendationsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.training = this.route.snapshot.queryParams['training'] == 'true';
  }

  ngOnInit(): void {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.windowWidth = document.body.clientWidth;
    const resizeObservable$ = fromEvent(window, 'resize');
    const sub = resizeObservable$.subscribe((evt) => {
      this.windowWidth = document.body.clientWidth;
    });

    this.subs.push(sub);

    this.cartProductItems = [];
    this.cartRecipeItems = [];
    this.fetchCartItems();

    const sub2 = this._requestService.storageChange.subscribe(() => {
      this.fetchCartItems();
      //this._ref.detectChanges();
    });
    this.subs.push(sub2);

    this.getConfig();
  }

  getConfig() {
    this.apiService.getbrandConfig$.subscribe((res) => {
      if (!res) return;
      this.StopOnlineDeliveryOrders = res.StopOnlineDeliveryOrders;
      this.StopOnlineCollectionOrders = res.StopOnlineCollectionOrders;
      this.StopOnlineEatInOrders = res.StopOnlineEatInOrders;
      this.getWebCustomer();
    });
  }

  getWebCustomer() {
    const sub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.buttonRadius = res.TemplateData.DynamicStyle?.ButtonRadius;
      this.emptyCartMessage = res.TemplateData.CartMessage?.EmptyCartMessage;
      this.sideCartHeaderText =
        res.TemplateData.CheckoutButtonsText?.SideCartHeaderText;
      this.hotCatogries = res.TemplateData?.HotCategories;
      this.PopupOnKgItems = res.TemplateData?.AdditionalDetail?.PopupOnKgItems;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.sideCartButtonText =
        res.TemplateData.CheckoutButtonsText?.SideCartButtonText;

      if (res.TemplateData.CartMessage?.StopOnlineOrders)
        this.stopOnlineOrders = res.TemplateData.CartMessage?.StopOnlineOrders;

      this.checkForDeliveryType();
    });

    this.subs.push(sub);
  }

  checkForDeliveryType() {
    
    const sub3 = this._requestService.deliveryType$.subscribe((type) => {
      this.stopOnlineOrdersMessage = undefined;
      if (type == 'Home' && this.StopOnlineDeliveryOrders)
        this.stopOnlineOrdersMessage = this.stopOnlineOrders.Delivery;

      if (type == 'Store' && this.StopOnlineCollectionOrders)
        this.stopOnlineOrdersMessage = this.stopOnlineOrders.Collection;

      if (type == 'EatIn' && this.StopOnlineEatInOrders)
        this.stopOnlineOrdersMessage = this.stopOnlineOrders.EatIn;
    });

    this.subs.push(sub3);
  }

  fetchCartItems() {
    this.cartRecipeItems = this._requestService.getRecipiesItemsForSideCart();
    this.cartProductItems = this._requestService.getProductItemsForSideCart();
    this.cartProductItems.forEach((value) => {
      if (value.ProductQuantity == 0) {
        this.removeProductItem(value);
      }
    });

    this.getCartTotal();
  }

  cartRecipeUpdation(flag, item) {
    if (flag == '-') {
      if (item.RecipeQuantity == 1) {
        this.cartRecipeItems.splice(this.cartRecipeItems.indexOf(item), 1);
      } else {
        let tmp_total = item.recipeTotal / item.RecipeQuantity;
        item.recipeTotal = item.recipeTotal - tmp_total;
        item.RecipeQuantity = item.RecipeQuantity - 1;
      }
    } else {
      if (item.RecipeMaxBuyAllowed == item.RecipeQuantity) return;
      let tmp_total = item.recipeTotal / item.RecipeQuantity;
      item.recipeTotal = item.recipeTotal + tmp_total;
      item.RecipeQuantity = item.RecipeQuantity + 1;
    }

    this._local.set('cartRecipeItems', JSON.stringify(this.cartRecipeItems));
    this.getCartTotal();
    this._requestService.updateCartCounter();
    var data = {
      item: item,
      flag: flag,
    };
    this.updateCart.next(data);
  }

  cartProductUpdation(flag, item) {
    if (flag == '-') {
      if (item.ProductQuantity == 1) {
        item.ProductQuantity = 0;
        this.cartProductItems.splice(this.cartProductItems.indexOf(item), 1);
      } else {
        let tmp_total = item.ProductTotal / item.ProductQuantity;
        item.ProductTotal = item.ProductTotal - tmp_total;
        item.ProductQuantity = item.ProductQuantity - 1;
      }
    } else {
      let tmp_total = item.ProductTotal / item.ProductQuantity;
      item.ProductTotal = item.ProductTotal + tmp_total;
      item.ProductQuantity = item.ProductQuantity + 1;
    }

    this._local.set('cartProductItems', JSON.stringify(this.cartProductItems));
    this.getCartTotal();
    this._requestService.updateCartCounter();
    var data = {
      item: item,
      flag: flag,
    };
    this.updateCart.next(data);
  }

  kgListChanged(product) {
    let cart = JSON.parse(this._local.get('cartProductItems'));
    cart?.forEach((element) => {
      if (element.ProductId == product.ProductId) {
        element.ProductQuantity = product.ProductQuantity;
        element.ProductTotal =
          element.ProductQuantity * element.ProductOldPrice;
      }
    });
    this._local.set('cartProductItems', JSON.stringify(cart));
    this._requestService.storageChange.next();
    this.updateKgList.next(product);
    this.getCartTotal();
  }

  getCartTotal() {
    let productTotal = 0;
    let recipeTotal = 0;

    if (this.cartProductItems.length != 0) {
      this.cartProductItems.forEach((val) => {
        productTotal = productTotal + val.ProductTotal;
      });
    }

    if (this.cartRecipeItems.length != 0) {
      this.cartRecipeItems.forEach((val) => {
        recipeTotal = recipeTotal + val.recipeTotal;
      });
    }

    this.total = productTotal + recipeTotal;
  }

  callCheckout() {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'Checkout', {
        currency: 'GBP',
        value: this.getCartTotal(),
        items: this.cartRecipeItems,
      });
    }

    if (this.training) {
      this.router.navigate([this._requestService.setUrl('checkout')], {
        queryParams: { training: true },
      });
      return;
    }

    this.recomendationsService.showRecomendations.next(null);
  }

  removeRecipeItem(item) {
    this.cartRecipeItems.splice(this.cartRecipeItems.indexOf(item), 1);
    this._local.set('cartRecipeItems', JSON.stringify(this.cartRecipeItems));
    this._requestService.updateCartCounter();
    var data = {
      item: item,
      flag: 'removed',
    };
    this.getCartTotal();
    this.updateCart.next(data);
  }

  removeProductItem(item) {
    this.cartProductItems.splice(this.cartProductItems.indexOf(item), 1);
    this._local.set('cartProductItems', JSON.stringify(this.cartProductItems));
    this._requestService.updateCartCounter();
    var data = {
      item: item,
      flag: 'removed',
    };
    this.updateCart.next(data);
    this.getCartTotal();
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
}

export const STOPONLINEORDERS = {
  Delivery: {
    Message:
      'Sorry for the inconvenience, Home Delivery is not avaiable at moment',
    TextColor: 'gold',
    BGColor: 'lightgray',
    border: 'black',
  },
  Collection: {
    Message:
      'Sorry for the inconvenience, Collection is not avaiable at moment',
    TextColor: 'gold',
    BGColor: 'lightgray',
    border: 'black',
  },
  EatIn: {
    Message: 'Sorry for the inconvenience,EatIn is not avaiable at moment',
    TextColor: 'gold',
    BGColor: 'lightgray',
    border: 'black',
  },
  WalkIn: {
    Message: 'Sorry for the inconvenience, WalkIn is not avaiable at moment',
    TextColor: 'gold',
    BGColor: 'lightgray',
    border: 'black',
  },
};
