<div class="container mt-3 mb-1">
    <div class="row justify-content-center">
      <div class="col-md-4 col-sm-6 p-0 background">
        <div class="position-relative cursor-pointer" (click)="openUrl()">
          <img
            [src]="product.ProductImageUrl"
            [alt]="product.ProductTitle"
            class="w-100"
          />
          <div
            *ngIf="product.ProductStockStatus == 'Sold'"
            class="position-absolute w-100 h-100 top-0 start-0"
          >
            <img
              src="https://perkss.blob.core.windows.net/images/camcarsales/cardetail/wtrmrk/sold.png"
              alt=""
              class="w-100 h-100 opacity-50"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6 ps-sm-5 background">
        <div class="row">
          <div class="col">
            <h2>
              {{ product.ProductCategory }} {{ product.ProductSubCategoryTitle }}
              {{ product.ProductTitle }}
            </h2>
            <p>{{ product.ProductLabel }}</p>
            <ul class="ps-4">
              <span
                *ngFor="
                  let item of product.ProductExtraObject?.KeyFacts | keyvalue
                "
              >
                <li *ngIf="item.value" class="square text-muted fs-6 mb-2">
                  {{ item.key }} : {{ item.value }}
                </li></span
              >
            </ul>
          </div>
          <div class="col">
            <h2 class="price-color">
              {{ product.ProductNewPrice | currency: "GBP":"symbol":"1.2-2" }}
            </h2>
  
            <div class="mb-4">
              <button class="btn btn-lg price-btn-background" (click)="openUrl()">
                More Info
              </button>
            </div>
  
            <div class="mb-4" *ngIf="product.ProductStockStatus == 'Available'">
              <button
                class="btn btn-lg price-btn-background"
                (click)="enquireNow()"
              >
                Enquire Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  