// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: require('../../package.json').version + ' --DEV',
  apiUrl: 'https://dev-perkss.azurewebsites.net/', //DEVELOPMENT,
  PaymentSenseSDK:
    'https://web.e.test.connect.paymentsense.cloud/assets/js/client.js',

  SquareupSDK: 'https://sandbox.web.squarecdn.com/v1/square.js',

  apiPemKeyPublic: `-----BEGIN PUBLIC KEY-----
    MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDiuE4yO9V+G66a5cX+RqT2+1BA
    PYkp2kmIwU2ubdVqk8RTE/TOPIIzLnAOMvZOrfokDI2nvqeoqg8eVI6Y2jrqA75T
    m+xpVwmojM4310pV7XabQRppYBwtPac412IggrrxpvL0kYUtCpoaoJAQuh/Ivu3k
    6T4umdnZS0rdnneLQQIDAQAB
    -----END PUBLIC KEY-----`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown ok.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
