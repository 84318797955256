import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { GetterService } from 'src/app/service/getter-service.service';
import { Product } from 'src/Model/Product-Interfaces';
import { SelectDeliveryType } from '../../select-delivery-type/select-delivery-type.service';
import { ProductCardService } from '../product-card.service';

@Component({
  selector: 'app-responsive-grid-style',
  templateUrl: './responsive-grid-style.component.html',
  styleUrls: ['./responsive-grid-style.component.scss'],
})
export class ResponsiveGridStyleComponent implements OnInit, OnDestroy {
  @Input() products: Product[] = [];
  @Input() deleteCart: boolean = false;
  @Input() redirectToDetails: boolean = false;
  @Output() quantityChanged = new EventEmitter();
  SelectedButtonColor;
  SelectedButtonTextColor;
  LandingPostcodeValidation: boolean;
  PopupOnKgItems: boolean;
  ItemBackgroundColor: string;
  ItemTitleColor: string;
  deliveryTypePopup: boolean;
  webCusSub: Subscription;
  constructor(
    private apiService: ApiService,
    private _local: GetterService,
    private spinner: NgxSpinnerService,
    private productCardService: ProductCardService,
    private selectService: SelectDeliveryType
  ) {}

  ngOnInit(): void {
    this.getWebcustomer();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  getWebcustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.deliveryTypePopup = res.TemplateData?.DeliveryTypePopup;
      this.ItemBackgroundColor =
        res.TemplateData.DynamicStyle.SecondaryColors?.ItemBackgroundColor;
      this.ItemTitleColor =
        res.TemplateData.DynamicStyle.SecondaryColors?.ItemTitleColor;
      this.PopupOnKgItems = res.TemplateData?.AdditionalDetail?.PopupOnKgItems;
      this.LandingPostcodeValidation =
        res.TemplateData.CheckoutDetail.LandingPostcodeValidation;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
    });
  }
  addRemoveFavourite(product: Product, status: boolean) {
    if (!this._local.get('CustomerId')) {
      this.openUrl('login');
      return;
    }
    this.productCardService.addRemoveFavs(product, status);
  }
  quantityControl(product: Product, flag: string) {
    this.productCardService.quantityControl(product, flag);
    this.quantityChanged.emit();
  }
  getProductModal(product: Product) {
    if (this.redirectToDetails) {
      const brandFlavor = window.location.pathname.split('/')[1];
      window.open(
        `${window.location.protocol}//${window.location.host}/${brandFlavor}/products/product-details/${product.ProductId}`,
        '_blank'
      );
      return;
    }
    if (this.deliveryTypePopup && !this._local.get('deliveryType').length) {
      this.selectService.showPopup.next();
      return;
    }
   // this.spinner.show();
    this.productCardService.getProductModal(product);
  }
  RemoveFavourite(product: Product) {
    this.productCardService.RemoveFavourite(product);
  }
  openUrl(url: string) {
    this.productCardService.openUrl(url);
  }
  kgListChanged(product: Product) {
    this.productCardService.kgListChanged(product);
  }
}
