<div
  class="position-sticky top-0 p-3 w-75 min-vh-100"
  [ngStyle]="{
    'background-color': ProductSideMenuStyle?.BackgroundColor
  }"
>
  <span class="caretBack">
    <i class="fas fa-angle-left" aria-hidden="true"></i>Back
  </span>
  <!-- Menu Title -->
  <h1
    class="mb-0 p-2"
    [ngStyle]="{
      color: ProductSideMenuStyle?.MenuTitleColor
    }"
  >
    {{ ProductSideMenu?.MenuTitle }}
    <span
      *ngIf="!ProductionEnvironment"
      [ngStyle]="{
        color: ProductSideMenuStyle?.MenuTitleColor
      }"
    >
      -Test Version
    </span>
  </h1>
  <hr
    class="mt-0 opacity-100 border-2"
    [ngStyle]="{
      'border-top-color': ProductSideMenuStyle?.DividerColor
    }"
  />

  <!-- Categories -->
  <div>
    <ul
      *ngFor="let category of brand_Categories; let i = index"
      class="categoryWrapper p-0"
    >
      <li
        *ngIf="category?.Status"
        class="categoryItem rounded-pill"
        (click)="
          currentComponent != 'store'
            ? openCat(category, $event)
            : catCliked(category)
        "
        [attr.aria-expanded]="selectedCatId == category.CategoryId"
        [ngStyle]="
          selectedCatId == category.CategoryId
            ? {
                'background-color':
                  ProductSideMenuStyle?.SelectedMainItemBgColor,
                color: ProductSideMenuStyle?.SelectedMainItemTextColor
              }
            : {
                'background-color':
                  ProductSideMenuStyle?.UnSelectedMainItemBgColor ||
                  'transparent',
                color:
                  ProductSideMenuStyle?.UnSelectedMainItemTextColor ||
                  ProductSideMenuStyle?.UnSelectedMainItemCounterTextColor
              }
        "
      >
        <span>{{ category?.CategoryTitle }}</span>
        <div class="d-flex align-items-center">
          <!-- Counter -->
          <span
            class="badge rounded-pill me-3"
            *ngIf="ProductSideMenu?.ShowCounters"
            [ngStyle]="
              selectedCatId == category.CategoryId
                ? {
                    'background-color':
                      ProductSideMenuStyle?.SelectedMainItemCounterColor,
                    color:
                      ProductSideMenuStyle?.SelectedMainItemCounterTextColor
                  }
                : {
                    'background-color':
                      ProductSideMenuStyle?.UnSelectedMainItemCounterColor,
                    color:
                      ProductSideMenuStyle?.UnSelectedMainItemCounterTextColor
                  }
            "
          >
            {{ category.Subcategories?.length ||
                      counters?.cats?.[category.CategoryId] }}
          </span>
          <!-- Dropdown Arrow -->
          <span
            [ngStyle]="{
              color:
                selectedCatId == category.CategoryId
                  ? ProductSideMenuStyle?.SelectedDropdownArrowColor
                  : ProductSideMenuStyle?.UnSelectedDropdownArrowColor
            }"
          >
            <i
              class="fas fa-angle-right itemCaret"
              aria-hidden="true"
              *ngIf="category?.['Subcategories']?.length"
            ></i>
          </span>
        </div>
      </li>

      <!-- Subcategories -->
      <ul
        *ngIf="selectedCatId == category.CategoryId && category?.['Subcategories']?.length"
        class="subcat-wrapper position-relative"
        [ngStyle]="{
          'border-color': ProductSideMenuStyle?.VerticalLineForSubItemsColor
        }"
      >
        <li
          class="subcategoryItem rounded-pill categoryWrapper"
          *ngFor="let subcat of category?.['Subcategories']; let i = index"
          (click)="openSubCat(category, subcat, $event)"
          [ngStyle]="
            selectedSubCat == subcat.SubcategoryId
              ? {
                  'background-color':
                    ProductSideMenuStyle?.SelectedSubItemBgColor,
                  color: ProductSideMenuStyle?.SelectedSubItemTextColor
                }
              : {
                  'background-color':
                    ProductSideMenuStyle?.UnSelectedSubItemBgColor,
                  color:
                    ProductSideMenuStyle?.UnSelectedSubItemTextColor ||
                    ProductSideMenuStyle?.UnSelectedSubItemCounterTextColor
                }
          "
        >
          <span
            *ngIf="selectedSubCat == subcat.SubcategoryId"
            class="rounded-circle d-inline-block position-absolute selected-subcat-circle"
            [ngStyle]="{
              background: ProductSideMenuStyle?.PointerColorForSubItemSelected
            }"
          ></span>

          {{ subcat?.SubcategoryTitle }}
          <!-- Counter -->
          <span
            class="badge rounded-pill"
            *ngIf="ProductSideMenu?.ShowCounters"
            [ngStyle]="
              selectedSubCat == subcat.SubcategoryId
                ? {
                    'background-color':
                      ProductSideMenuStyle?.SelectedSubItemCounterColor,
                    color: ProductSideMenuStyle?.SelectedSubItemCounterTextColor
                  }
                : {
                    'background-color':
                      ProductSideMenuStyle?.UnSelectedSubItemCounterColor,
                    color:
                      ProductSideMenuStyle?.UnSelectedSubItemCounterTextColor
                  }
            "
          >
            {{ counters?.subCats?.[subcat.SubcategoryId] }}
          </span>
        </li>
      </ul>
    </ul>
  </div>
</div>
