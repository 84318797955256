import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WebCustomer } from '../../Model/WebCustomer';
import { BrandConfig } from '../../Model/Brand-Config-Interface';
import { NavigationStart, Router } from '@angular/router';
import { pki } from 'node-forge';
import { DatePipe } from '@angular/common';

const Env = environment;
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private webCustomerStore = new BehaviorSubject<WebCustomer>(null);
  private brandConfigStore = new BehaviorSubject<BrandConfig>(null);
  getbrandConfig$: Observable<BrandConfig> =
    this.brandConfigStore.asObservable();
  getWebcustomer$: Observable<WebCustomer> =
    this.webCustomerStore.asObservable();
  brandConfigSub: Subscription;
  brandFlavour: string;
  
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.brandFlavour = event.url.split('/')[1];
        const webCustomer = this.webCustomerStore.getValue();
        const brandConfig = this.brandConfigStore.getValue();
        if (!webCustomer) {
          this.getWebCustomer();
        }
        if (!brandConfig) {
          this.getBrandConfig();
        }
      }
    });
  }

  private getBrandConfig(postcode = null, deliveryType = null) {
    if (this.brandConfigSub) {
      this.brandConfigSub.unsubscribe();
    }

    if (!this.brandFlavour) {
      alert(
        'Brand Flavor Missing! Please Contact Customer Support to place an order'
      );
      return;
    }

    const key = this.getAPIKey(`api/Order/v2/getConfig/${this.brandFlavour}`);
    const headers = new HttpHeaders({
      ApiKey: key, //api starts from api/...
    });
    const body = {};
    if (postcode) {
      body['Postcode'] = postcode;
    }
    if (deliveryType) {
      body['OrderOption'] = deliveryType == 'Home' ? 'Delivery' : 'Pickup';
    }

    this.brandConfigSub = this.http
      .post(Env.apiUrl + 'api/Order/v2/getConfig/' + this.brandFlavour, body, {
        headers,
      })
      .pipe(shareReplay())
      .subscribe({
        next: (res: any) => {
          if (res.Info.Status == 200) {
            this.brandConfigStore.next(res['Data']);
            return;
          }
          alert(res.Info.Message);
        },
        error: (error) => {
          //alert(error.message);
          alert('Network Error! Please refresh page');
        },
      });
  }

  private getWebCustomer() {

    if (!this.brandFlavour) {
      alert(
        'Brand Flavor Missing! Please Contact Customer Support to place an order'
      );
      return;
    }

    const key = this.getAPIKey(
      `api/Brand/getBrandInfo/${this.brandFlavour}/WebCustomer`
    );
    const headers = new HttpHeaders({
      ApiKey: key, //api starts from api/...
    });
    this.http
      .get(
        Env.apiUrl +
          'api/Brand/getBrandInfo/' +
          this.brandFlavour +
          '/WebCustomer',
        {
          headers,
        }
      )
      .pipe(shareReplay())
      .subscribe({
        next: (res: any) => {
          if (res.Info.Status == 200) {
            this.webCustomerStore.next(res['Data']);
            return;
          }
          alert(res.Info.Message);
        },
        error: (error) => {
          //alert(error.message);
          alert('Network Error! Please refresh page');
        },
      });
  }
  /* only to update slots data in case of delivery in checkout */
  callBrandConfig(postcode: string, deliveryType: string) {
    if (!deliveryType) return;
    this.getBrandConfig(postcode, deliveryType);
  }

  private getAPIKey(url: string): string {
    const date = new Date();
    const modifiedDate = this.datePipe.transform(
      date,
      'yyyy-MM-dd hh:mm:ss',
      'GMT'
    );
    const brandFlavor = window.location.pathname.split('/')[1];

    const rsa = pki.publicKeyFromPem(environment.apiPemKeyPublic);

    return window.btoa(rsa.encrypt(`${modifiedDate}#${brandFlavor}#${url}`));
  }
}
