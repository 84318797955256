import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchedCarComponent } from './searched-car.component';

@NgModule({
  declarations: [SearchedCarComponent],
  imports: [CommonModule],
  exports: [SearchedCarComponent],
})
export class SearchedCarModule {}
