<hr
	class="m-0"
	style="border: 2px solid maroon; background-color: maroon"
/>
<div class="allergensContainer" style="display: block; font-size: 12px">
	<h2 class="text-center">Food Allergies & Intolerance</h2>
	<div class="AllergensContainer" *ngIf="allergens?.length">
		<div *ngFor="let allergen of allergens" class="allergenItem">
			<span
				class="square-tag-footer d-block mx-auto"
				title="{{ allergen.Title }}"
				*ngIf="allergen.Link"
			>
				<img src="{{ allergen.Link }}" alt="tag" />
			</span>
			<p class="text-center">{{ allergen.Title }}</p>
		</div>
	</div>
	<p
		class="AllergensBottomText p-2 text-sm-center text-justify"
		*ngIf="AllergensBottomText && !showDineInBottomText"
		[ngStyle]="{
			color: AllergensBottomTextColor,
			'background-color': AllergensBottomTextBackgroundColor
		}"
	>
		{{ AllergensBottomText }}
	</p>
	<p
		class="AllergensBottomText p-2 text-justify text-sm-center"
		*ngIf="DineInBottomText && showDineInBottomText"
		[ngStyle]="{
			color: AllergensBottomTextColor,
			'background-color': AllergensBottomTextBackgroundColor
		}"
	>
		{{ DineInBottomText }}
	</p>
</div>
<!-- Coments -->
