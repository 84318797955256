import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, shareReplay, Subject } from 'rxjs';
import { GetterService } from './getter-service.service';
import { CartProdut, CartRecipies } from 'src/Model/Checkout-Interfaces';
import { Category, SubCategory } from 'src/Model/Product-Interfaces';
import { ApiService } from './api.service';
import { DatePipe } from '@angular/common';
import * as forge from 'node-forge';
interface SideMenuData {
  cat: Category;
  subCat: SubCategory;
}
@Injectable({
  providedIn: 'root',
})
export class RequestService {
  redirectToComponent: string;
  cartCounter$ = new BehaviorSubject<number>(0);
  postCode: string = ''; // to populate checkout postcode from menu
  headerCheckoutBtn = new Subject<null>();
  sideCatChanged = new Subject<SideMenuData>();
  sideCatClicked = new Subject<SideMenuData>();
  resetSideCats = new Subject<void>();
  /* Delivery Type */
  storageChange = new Subject<void>();

  /* For updating delivery type from header component to other components */
  deliveryType$: BehaviorSubject<'Home' | 'Store' | 'EatIn' | ''> =
    new BehaviorSubject('');

  sub: any;
  /* TemplateType */

  constructor(
    private _http: HttpClient,
    private _local: GetterService,
    private apiService: ApiService,
    private datePipe: DatePipe
  ) {
    this.updateCartCounter();
  }

  getRequest(url): Observable<any> {
    const key = this.getAPIKey(url);
    const headers = new HttpHeaders({
      ApiKey: key, //api starts from api/...
    });

    return this._http
      .get(environment.apiUrl + url, { headers: headers })
      .pipe(shareReplay(1));
  }

  deleteRequest(url): Observable<any> {
    const key = this.getAPIKey(url);
    const headers = new HttpHeaders({
      ApiKey: key, //api starts from api/...
    });
    return this._http
      .delete(environment.apiUrl + url, { headers: headers })
      .pipe(shareReplay(1));
  }

  postRequest(url, params, token = ''): Observable<any> {
    const key = this.getAPIKey(url);

    const headers = new HttpHeaders({
      ApiKey: key, //api starts from api/...
      Authorization: token,
    });

    return this._http
      .post<any>(environment.apiUrl + url, params, {
        headers: headers,
      })
      .pipe(shareReplay(1));
  }

  putRequest(url, params): Observable<any> {
    const key = this.getAPIKey(url);
    const headers = new HttpHeaders({
      ApiKey: key, //api starts from api/...
    });
    return this._http
      .put(environment.apiUrl + url, params, {
        headers: headers,
      })
      .pipe(shareReplay(1));
  }

  getAPIKey(url: string): string {
    const date = new Date();
    const modifiedDate = this.datePipe.transform(
      date,
      'yyyy-MM-dd hh:mm:ss',
      'GMT'
    );
    const brandFlavor = window.location.pathname.split('/')[1];

    const rsa = forge.pki.publicKeyFromPem(environment.apiPemKeyPublic);

    return window.btoa(rsa.encrypt(`${modifiedDate}#${brandFlavor}#${url}`));
  }

  isLoggedIn() {
    return !!this._local.get('access_token');
  }

  isGuestLoggedIn() {
    return !!this._local.getSessionItem('access_token');
  }

  saveProductCartItems(products: CartProdut[]) {
    this._local.set('cartProductItems', JSON.stringify(products));
    this.updateCartCounter();
    this.storageChange.next();
  }

  saveRecipeCartItems(recipes) {
    this._local.set('cartRecipeItems', JSON.stringify(recipes));
    this.updateCartCounter();
    this.storageChange.next();
  }

  setUrl(pageName) {
    let brandFlavour = window.location.pathname.split('/')[1];
    return brandFlavour + '/' + pageName;
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  getRecipiesItemsForSideCart() {
    const recipes = this._local.get('cartRecipeItems')
      ? JSON.parse(this._local.get('cartRecipeItems'))
      : [];
    return recipes;
  }
  
  getProductItemsForSideCart() {
    const products = this._local.get('cartProductItems')
      ? JSON.parse(this._local.get('cartProductItems'))
      : [];
    return products;
  }

  getCartRecipeItems() {
    const recipes = [];
    let recipeCart = JSON.parse(this._local.get('cartRecipeItems'));
    recipeCart?.forEach((value) => {
      let extraArray: any[] = [];
      if (
        value.RecipeExtraObject != null &&
        value.RecipeExtraObject.length != 0
      ) {
        value.RecipeExtraObject.forEach((extra) => {
          extraArray.push({
            ExtraTitle: extra.Title,
            ExtraQuantity: extra.Quantity,
            ExtraPrice: extra.Price,
          });
        });
      }
      recipes.push({
        ItemId: value.RecipeId,
        Quantity: value.RecipeQuantity,
        TotalPrice: value.RecipePrice,
        IsDiscountedItem: value.IsDiscountedItem
          ? value.IsDiscountedItem
          : false,
        SocialDiscountItem: value?.socialDiscountItem
          ? value?.socialDiscountItem
          : false,
        ExtraDetail: JSON.stringify(extraArray),
      });
    });
    return recipes;
  }

  getProductCartItems() {
    const products = [];
    let productCart = JSON.parse(this._local.get('cartProductItems'));
    productCart?.forEach((value) => {
      let extraArray: any[] = [];
      if (
        value.ProductExtraObject != null &&
        value.ProductExtraObject.length != 0
      ) {
        value.ProductExtraObject.forEach((extra) => {
          extraArray.push({
            ExtraTitle: extra.Title,
            ExtraQuantity: extra.Quantity,
            ExtraPrice: extra.Price,
          });
        });
      }
      products.push({
        ItemId: value.ProductId,
        Quantity: value.ProductQuantity,
        TotalPrice: value.ProductOldPrice,
        IsDiscountedItem: value.IsDiscountedItem
          ? value.IsDiscountedItem
          : false,
        SocialDiscountItem: value?.socialDiscountItem
          ? value?.socialDiscountItem
          : false,
        ExtraDetail: JSON.stringify(extraArray),
      });
    });
    return products;
  }

  updateCartCounter() {
    this.sub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) return;

      let cartProductItems: CartProdut[] = this._local.get('cartProductItems')
        ? JSON.parse(this._local.get('cartProductItems'))
        : [];
      let cartRecipeItems: CartRecipies[] = this._local.get('cartRecipeItems')
        ? JSON.parse(this._local.get('cartRecipeItems'))
        : [];

      let productsCounter = 0;
      let reciepsCounter = 0;

      if (res.TemplateData.RecipesBrand) {
        cartProductItems.forEach((product) => {
          productsCounter += product.ProductQuantity;
        });

        cartRecipeItems.forEach((recipe) => {
          reciepsCounter += recipe.RecipeQuantity;
        });
      } else {
        productsCounter = cartProductItems.length;
        reciepsCounter = cartRecipeItems.length;
      }

      this.cartCounter$.next(reciepsCounter + productsCounter);

      if (this.sub) {
        this.sub.unsubscribe();
      }
    });
  }
}
