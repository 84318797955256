import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponsiveGridStyleComponent } from './responsive-grid-style.component';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [ResponsiveGridStyleComponent],
  imports: [CommonModule, FormsModule, NgxSpinnerModule],
  exports: [ResponsiveGridStyleComponent],
})
export class ResponsiveGridStyleModule {}
