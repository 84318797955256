<div class="overlay" *ngIf="showPopup" (click)="closeModel()">
  <div
    class="popup"
    *ngIf="!MenuBuilder && !dineIn && !mergePopups"
    (click)="stopPropagation($event)"
  >
    <div class="popup-header">
      <h2 class="modalHeading">
        Specify your {{ filteredRecipe ? "Dish" : "Product" }}
      </h2>
      <button type="button" class="close fs-3" (click)="closeModel()">
        &times;
      </button>
    </div>
    <div class="popup-body" *ngIf="filteredRecipe">
      <div class="name p-2">
        <div
          *ngIf="filteredRecipe?.RecipeImage"
          class="d-flex justify-content-center align-items-center mb-2"
        >
          <img src="{{ filteredRecipe?.RecipeImage }}" alt="Recipe image" />
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="text-light-black fw-700 font-weight-bolder m-0">
            {{ filteredRecipe?.RecipeTitle }}
          </h2>
          <h5
            class="text-light-black fw-600 no-margin position"
            *ngIf="filteredRecipe?.RecipePrice != 0"
          >
            {{
              filteredRecipe?.RecipePrice
                | currency : "GBP" : "symbol" : "1.2-2"
            }}
          </h5>
        </div>
        <p class="text-start m-0">{{ filteredRecipe?.RecipeDescription }}</p>
      </div>

      <div class="product-quantity padding-10">
        <span class="text-light-black fw-700 fs-16">Quantity</span>
        <div class="input-group br-40px border w-auto flex-nowrap">
          <button class="btn" (click)="quantityControl('-')" type="button">
            <i class="fas fa-minus"></i>
          </button>
          <span class="input-group-text m-0">{{ quantity }}</span>
          <button
            class="btn"
            type="button"
            (click)="quantityControl('+')"
            [disabled]="
              filteredRecipe.RecipeMaxBuyAllowed == quantity ||
              addToCartRemainingLimit == quantity
            "
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>

      <!-- sub product -->
      <div *ngIf="filteredRecipe?.RecipeExtraObject" class="additional-product">
        <div
          *ngFor="let extra of renderedExtraList; let i = index"
          class="card"
        >
          <div
            class="card-header"
            *ngIf="extra?.Key.toLowerCase().includes('choose')"
          >
            <div class="container">
              <div class="row text-start">
                <div class="col-lg-12 col-md-12" style="margin-bottom: 5px">
                  <button
                    type="button"
                    disabled
                    class="btn btn-warning btn-lg extra-title btnChooseOne"
                  >
                    {{ extra?.Key }}
                  </button>
                </div>
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div
                        *ngFor="let item of extra?.extras"
                        class="custom-control custom-radio"
                      >
                        <div class="row mt-3">
                          <div class="col-9">
                            <div class="form-check">
                              <input
                                (click)="
                                  selectExtra(item, filteredRecipe?.RecipePrice)
                                "
                                type="radio"
                                name="{{ item?.Key }}{{ i }}"
                                id="extra{{ item?.Title }}{{ item?.Key }}{{
                                  i
                                }}"
                                class="form-check-input"
                              />
                              <label
                                class="form-check-label cursor-pointer"
                                for="extra{{ item?.Title }}{{ item?.Key }}{{
                                  i
                                }}"
                              >
                                <p class="m-0">{{ item?.Title }}</p>
                              </label>
                            </div>
                          </div>
                          <div class="col-3">
                            <p *ngIf="item?.Price != 0" class="text-end m-0">
                              {{
                                item?.Price
                                  | currency : "GBP" : "symbol" : "1.2-2"
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-header"
            *ngIf="extra?.Key.toLowerCase().includes('optional')"
          >
            <div class="container">
              <div class="row text-start">
                <div class="col-lg-12 col-md-12" style="margin-bottom: 5px">
                  <button
                    type="button"
                    disabled
                    class="btn btn-warning btn-lg extra-title btnChooseOne"
                  >
                    {{ extra?.Key }}
                  </button>
                  <p class="text-danger m-0" *ngIf="extra?.maxQuantity">
                    Optional: select a maximum of
                    {{ extra.maxQuantity }}
                  </p>
                </div>
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div
                        *ngFor="let item of extra?.extras"
                        class="custom-control custom-radio"
                      >
                        <div class="row mt-3">
                          <div class="col-9">
                            <div class="form-check">
                              <label
                                class="form-check-label cursor-pointer"
                                for="extra{{ item?.Title }}{{ item?.Key }}{{
                                  i
                                }}"
                              >
                                <input
                                  type="checkbox"
                                  name="{{ item?.Key }}{{ i }}"
                                  id="extra{{ item?.Title }}{{ item?.Key }}{{
                                    i
                                  }}"
                                  (change)="
                                    selectOptionalExtra(
                                      item,
                                      filteredRecipe?.RecipePrice,

                                      extra
                                    )
                                  "
                                  class="form-check-input"
                                  [(ngModel)]="item.checked"
                                  value="extra{{ item?.Title }}{{ item?.Key }}{{
                                    i
                                  }}"
                                />
                                <p class="m-0">{{ item?.Title }}</p>
                              </label>
                            </div>
                          </div>
                          <div class="col-3">
                            <p *ngIf="item?.Price != 0" class="text-end m-0">
                              {{
                                item?.Price
                                  | currency : "GBP" : "symbol" : "1.2-2"
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-header"
            *ngIf="
              !extra?.Key.toLowerCase().includes('choose') &&
              !extra?.Key.toLowerCase().includes('optional') &&
              extra?.maxQuantity != 1
            "
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12" style="margin-bottom: 5px">
                  <button
                    type="button"
                    disabled
                    class="btn btn-warning btn-lg extra-title btnChooseOne"
                  >
                    {{ extra?.Key }}
                  </button>
                  <p class="text-danger m-0" *ngIf="extra?.maxQuantity">
                    Optional: select a maximum of
                    {{ extra.maxQuantity }}
                  </p>
                </div>
                <br />

                <div class="container">
                  <div class="row">
                    <div class="col-md-12 mx-auto">
                      <div
                        *ngFor="let item of extra?.extras; let i = index"
                        class="d-flex justify-content-between flex-row multipleExtras"
                      >
                        <div
                          class="d-flex align-item-center justify-content-between flex-row w-100"
                        >
                          <p class="m-0 w-50 text-start">{{ item?.Title }}</p>
                          <p class="m-0" *ngIf="item.Price">
                            {{
                              (item.Quantity * item.Price
                                ? item.Quantity * item.Price
                                : item.Price
                              ) | currency : "GBP" : "symbol" : "1.2-2"
                            }}
                          </p>
                          <div class="product-quantity">
                            <div class="input-group br-40px border flex-nowrap">
                              <button
                                class="btn"
                                (click)="multipleExtrasQuantity(item)"
                                type="button"
                              >
                                <i class="fas fa-minus"></i>
                              </button>
                              <span class="input-group-text m-0">{{
                                item.Quantity
                              }}</span>
                              <button
                                class="btn"
                                type="button"
                                (click)="
                                  selectExtra(
                                    item,
                                    filteredRecipe?.RecipePrice,
                                    extra
                                  )
                                "
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-header"
            *ngIf="
              !extra?.Key.toLowerCase().includes('choose') &&
              !extra?.Key.toLowerCase().includes('optional') &&
              extra?.maxQuantity == 1
            "
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12" style="margin-bottom: 5px">
                  <button
                    type="button"
                    disabled
                    class="btn btn-warning btn-lg extra-title btnChooseOne"
                  >
                    {{ extra?.Key }}
                  </button>
                </div>
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col-12 mx-auto">
                      <div
                        *ngFor="let item of extra?.extras"
                        class="custom-control custom-radio"
                      >
                        <div class="row mt-3">
                          <div class="col-9">
                            <div class="form-check">
                              <input
                                (click)="
                                  selectExtra(
                                    item,
                                    filteredRecipe?.RecipePrice,
                                    extra
                                  )
                                "
                                type="radio"
                                name="{{ item?.Key }}{{ i }}"
                                id="extra{{ item?.Title }}{{ item?.Key }}{{
                                  i
                                }}"
                                class="form-check-input"
                              />
                              <label
                                class="form-check-label cursor-pointer"
                                for="extra{{ item?.Title }}{{ item?.Key }}{{
                                  i
                                }}"
                              >
                                <p class="m-0">{{ item?.Title }}</p>
                              </label>
                            </div>
                          </div>
                          <div class="col-3">
                            <p *ngIf="item?.Price != 0" class="text-end m-0">
                              {{
                                item?.Price
                                  | currency : "GBP" : "symbol" : "1.2-2"
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- <div
                          class="d-flex align-item-center justify-content-between flex-row w-100"
                        >
                          <p class="m-0 w-50 text-start">{{ item?.Title }}</p>
                          <p class="m-0" *ngIf="item.Price">
                            {{
                              (item.Quantity * item.Price
                                ? item.Quantity * item.Price
                                : item.Price
                              ) | currency : "GBP" : "symbol" : "1.2-2"
                            }}
                          </p>
                          <div class="product-quantity">
                            <div class="input-group br-40px border flex-nowrap">
                              <button
                                class="btn"
                                (click)="multipleExtrasQuantity(item)"
                                type="button"
                              >
                                <i class="fas fa-minus"></i>
                              </button>
                              <span class="input-group-text m-0">{{
                                item.Quantity
                              }}</span>
                              <button
                                class="btn"
                                type="button"
                                (click)="
                                  selectExtra(
                                    item,
                                    filteredRecipe?.RecipePrice,
                                    extra
                                  )
                                "
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-body" *ngIf="filteredProduct">
      <div class="name p-2">
        <div
          *ngIf="filteredProduct?.ProductImageUrl"
          class="d-flex justify-content-center align-items-center"
        >
          <img
            src="{{ filteredProduct?.ProductImageUrl }}"
            alt="Recipe image"
          />
        </div>
        <div class="d-flex justify-content-between align-items-center mt-2">
          <h2 class="text-light-black fw-700 font-weight-bolder m-0">
            {{ filteredProduct?.ProductTitle }}
          </h2>
          <h5
            class="text-light-black fw-600 no-margin position"
            *ngIf="filteredRecipe?.RecipePrice != 0"
          >
            {{
              filteredProduct?.ProductOldPrice
                | currency : "GBP" : "symbol" : "1.2-2"
            }}
          </h5>
        </div>
        <div #description class="mt-2"></div>
      </div>

      <div
        class="product-quantity p-2"
        *ngIf="!filteredProduct.isDiscountedItem"
      >
        <span class="text-light-black fw-700 fs-16">Quantity</span>
        <div
          class="input-group br-40px border w-auto flex-nowrap"
          *ngIf="
            PopupOnKgItems
              ? !(filteredProduct.ProductUnits.toLowerCase() == 'kg')
              : true
          "
        >
          <button class="btn" (click)="quantityControl('-')" type="button">
            <i class="fas fa-minus"></i>
          </button>
          <span class="input-group-text m-0">{{ quantity }}</span>
          <button class="btn" type="button" (click)="quantityControl('+')">
            <i class="fas fa-plus"></i>
          </button>
        </div>
        <div
          *ngIf="
            PopupOnKgItems
              ? filteredProduct.ProductUnits.toLowerCase() == 'kg'
              : false
          "
        >
          <select
            name="kgList"
            id="kgList"
            class="form-select br-40px"
            [(ngModel)]="quantity"
            (change)="kgQuantity()"
          >
            <option value="0.5">0.5kg</option>
            <option value="1.0">1.0kg</option>
            <option value="1.5">1.5kg</option>
            <option value="2.0">2.0kg</option>
            <option value="2.5">2.5kg</option>
            <option value="3.0">3.0kg</option>
          </select>
        </div>
      </div>

      <!-- sub product -->
      <div
        *ngIf="filteredProduct?.ProductExtraObject != null"
        class="additional-product"
      >
        <div
          *ngFor="let extra of renderedExtraList; let i = index"
          class="card"
        >
          <div
            class="card-header"
            *ngIf="extra?.Key.toLowerCase().includes('choose')"
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12" style="margin-bottom: 5px">
                  <button
                    type="button"
                    disabled
                    class="btn btn-warning btn-lg extra-title btnChooseOne"
                  >
                    {{ extra?.Key }}
                  </button>
                </div>
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <div
                        *ngFor="let item of extra?.extras"
                        class="custom-control custom-radio"
                      >
                        <div class="row mt-3">
                          <div class="col-9">
                            <div class="form-check">
                              <input
                                (click)="
                                  selectExtra(
                                    item,
                                    filteredProduct?.ProductOldPrice
                                  )
                                "
                                type="radio"
                                name="{{ item?.Key }}{{ i }}"
                                id="extra{{ item?.Title }}{{ item?.Key }}{{
                                  i
                                }}"
                                class="form-check-input"
                              />
                              <label
                                class="form-check-label cursor-pointer"
                                for="extra{{ item?.Title }}{{ item?.Key }}{{
                                  i
                                }}"
                                ><p class="m-0">{{ item?.Title }}</p></label
                              >
                            </div>
                          </div>
                          <div class="col-3">
                            <p *ngIf="item?.Price != 0" class="text-end m-0">
                              {{
                                item?.Price
                                  | currency : "GBP" : "symbol" : "1.2-2"
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-header"
            *ngIf="!extra?.Key.toLowerCase().includes('choose')"
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12" style="margin-bottom: 5px">
                  <button
                    type="button"
                    disabled
                    class="btn btn-warning btn-lg extra-title btnChooseOne"
                  >
                    {{ extra?.Key }}
                  </button>
                </div>
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col-md-12 mx-auto">
                      <div
                        *ngFor="let item of extra?.extras; let i = index"
                        class="d-flex justify-content-between flex-row multipleExtras"
                      >
                        <div
                          class="d-flex align-item-center justify-content-between flex-row w-100"
                        >
                          <p class="m-0 w-50 text-start">{{ item?.Title }}</p>
                          <p class="m-0" *ngIf="item.Price">
                            {{
                              (item.Quantity * item.Price
                                ? item.Quantity * item.Price
                                : item.Price
                              ) | currency : "GBP" : "symbol" : "1.2-2"
                            }}
                          </p>
                          <div class="product-quantity">
                            <div
                              class="input-group br-40px border w-auto flex-nowrap"
                            >
                              <button
                                class="btn"
                                (click)="multipleExtrasQuantity(item)"
                                type="button"
                              >
                                <i class="fas fa-minus"></i>
                              </button>
                              <span class="input-group-text m-0">{{
                                item.Quantity
                              }}</span>
                              <button
                                class="btn"
                                type="button"
                                (click)="
                                  selectExtra(
                                    item,
                                    filteredProduct?.ProductOldPrice
                                  )
                                "
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-footer">
      <button
        (click)="
          addToCart(
            filteredRecipe ? filteredRecipe : filteredProduct,
            this.filteredRecipe ? 'recipe' : 'product'
          )
        "
        [disabled]="
          recipeTotal == 0 ||
          !(renderExtralistChooseOnes == extraArrayChooseOnes)
        "
        class="w-100 recipePopButton"
      >
        <span
          *ngIf="
            recipeTotal == 0 ||
            !(renderExtralistChooseOnes == extraArrayChooseOnes)
          "
        >
          <p class="m-0" *ngIf="renderExtralistChooseOnes == 1">
            Please choose an option
          </p>
          <p class="m-0" *ngIf="renderExtralistChooseOnes > 1">
            Please choose
            {{ renderExtralistChooseOnes - extraArrayChooseOnes }} more options
          </p>
        </span>
        <span
          *ngIf="
            recipeTotal != 0 &&
            renderExtralistChooseOnes == extraArrayChooseOnes
          "
          class="flexDisplay"
        >
          {{ addtoCartButtonText || "Add to cart" }}
          <span>
            {{ recipeTotal | currency : "GBP" : "symbol" : "1.2-2" }}
          </span>
        </span>
      </button>
    </div>
  </div>

  <div
    class="popup"
    *ngIf="MenuBuilder && !mergePopups"
    (click)="stopPropagation($event)"
  >
    <div class="popup-header">
      <h2 class="modalHeading">
        Specify your {{ filteredRecipe ? "Dish" : "Product" }}
      </h2>
      <button type="button" class="close fs-3" (click)="closeModel()">
        &times;
      </button>
    </div>
    <div class="popup-body" *ngIf="filteredRecipe">
      <div class="name p-2">
        <div *ngIf="filteredRecipe?.RecipeImage" class="mb-2">
          <img src="{{ filteredRecipe?.RecipeImage }}" alt="Recipe image" />
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="text-light-black fw-700 font-weight-bolder m-0">
            {{ filteredRecipe?.RecipeTitle }}
          </h2>
          <h5
            class="text-light-black fw-600 no-margin position"
            *ngIf="filteredRecipe?.RecipePrice"
          >
            {{
              filteredRecipe?.RecipePrice
                | currency : "GBP" : "symbol" : "1.2-2"
            }}
          </h5>
        </div>
        <p class="text-start m-0">{{ filteredRecipe?.RecipeDescription }}</p>
      </div>

      <div class="product-quantity p-2">
        <span class="text-light-black fw-700 fs-16">Quantity</span>
        <div class="input-group br-40px border w-auto flex-nowrap">
          <button class="btn" (click)="menuBuilderQuantity('-')" type="button">
            <i class="fas fa-minus"></i>
          </button>
          <span class="input-group-text m-0">{{ quantity }}</span>
          <button class="btn" type="button" (click)="menuBuilderQuantity('+')">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>

      <div *ngFor="let extra of renderedExtraList">
        <div
          class="p-2"
          *ngIf="selectedOptionsArray.indexOf(extra.Id) >= 0 ? true : false"
          [id]="extra.Id"
        >
          <div
            class="builerHeading"
            (click)="openCategory(extra.Id)"
            [ngStyle]="
              highLightCat(extra.Id)
                ? { 'background-color': 'rgb(122 191 138)' }
                : {}
            "
          >
            <p class="m-0" *ngIf="!itemTitleReplace(extra)">
              {{ extra.Title }}
            </p>
            <p class="m-0" *ngIf="itemTitleReplace(extra)">
              {{ itemTitleReplace(extra) }}
            </p>
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </div>
          <div *ngIf="selectedOptionId == extra.Id">
            <div
              class="br-40px bglight"
              (click)="openNextOption(extra, item)"
              *ngFor="let item of extra.Choose"
              [ngStyle]="
                highLightItem(extra.Id, item)
                  ? {
                      color: SelectedButtonTextColor,
                      'background-color': SelectedButtonColor
                    }
                  : {
                      color: item.TextColor,
                      'background-color': item.BackgroundColor
                    }
              "
            >
              <div class="builderItems">
                <p class="m-0 p-2">{{ item.Item }}</p>
                <p class="m-0" *ngIf="item.Price">
                  {{ item.Price | currency : "GBP" : "symbol" : "1.2-2" }}
                </p>
              </div>
              <!--<div
								*ngIf="!extra.Title.toLowerCase().includes('choose')"
								class="builderItems"
							>
								<p class="m-0 p-2">{{ item.Item }}</p>
								<p class="m-0" *ngIf="item.Price">
									{{ item.Price | currency: "GBP":"symbol":"1.2-2" }}
								</p>
								<div class="input-group br-40px border w-auto flex-nowrap">
									<button
										class="btn"
										(click)="menuBuilderQuantity('-')"
										type="button"
									>
										<i class="fas fa-minus"></i>
									</button>
									<span class="input-group-text m-0">{{ quantity }}</span>
									<button
										class="btn"
										type="button"
										(click)="menuBuilderQuantity('+')"
									>
										<i class="fas fa-plus"></i>
									</button>
								</div>
							</div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-footer">
      <button
        (click)="
          addToCart(
            filteredRecipe ? filteredRecipe : filteredProduct,
            this.filteredRecipe ? 'recipe' : 'product'
          )
        "
        [disabled]="disableCartbtn"
        class="w-100 recipePopButton"
      >
        <span class="flexDisplay">
          {{ addtoCartButtonText || "Add to cart" }}
          <span>
            {{ recipeTotal | currency : "GBP" : "symbol" : "1.2-2" }}
          </span>
        </span>
      </button>
    </div>
  </div>

  <div class="popup" *ngIf="mergePopups" (click)="stopPropagation($event)">
    <div class="popup-header">
      <h2 class="modalHeading">
        Specify your {{ filteredRecipe ? "Dish" : "Product" }}
      </h2>
      <button type="button" class="close fs-3" (click)="closeModel()">
        &times;
      </button>
    </div>
    <div class="popup-body" *ngIf="filteredRecipe">
      <div class="name p-2">
        <div *ngIf="filteredRecipe?.RecipeImage" class="mb-2">
          <img src="{{ filteredRecipe?.RecipeImage }}" alt="Recipe image" />
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="text-light-black fw-700 font-weight-bolder m-0">
            {{ filteredRecipe?.RecipeTitle }}
          </h2>
          <h5
            class="text-light-black fw-600 no-margin position"
            *ngIf="filteredRecipe?.RecipePrice"
          >
            {{
              filteredRecipe?.RecipePrice
                | currency : "GBP" : "symbol" : "1.2-2"
            }}
          </h5>
        </div>
        <p class="text-start m-0">{{ filteredRecipe?.RecipeDescription }}</p>
      </div>

      <div class="product-quantity p-2">
        <span class="text-light-black fw-700 fs-16">Quantity</span>
        <div class="input-group br-40px border w-auto flex-nowrap">
          <button class="btn" (click)="menuBuilderQuantity('-')" type="button">
            <i class="fas fa-minus"></i>
          </button>
          <span class="input-group-text m-0">{{ quantity }}</span>
          <button class="btn" type="button" (click)="menuBuilderQuantity('+')">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>

      <div *ngFor="let extra of renderedMenuBuilder">
        <div
          class="p-2"
          *ngIf="selectedOptionsArray.indexOf(extra.Id) >= 0 ? true : false"
          [id]="extra.Id"
        >
          <div
            class="builerHeading"
            (click)="openCategory(extra.Id)"
            [ngStyle]="
              highLightCat(extra.Id)
                ? { 'background-color': 'rgb(122 191 138)' }
                : {}
            "
          >
            <p class="m-0" *ngIf="!itemTitleReplace(extra)">
              {{ extra.Title }}
            </p>
            <p class="m-0" *ngIf="itemTitleReplace(extra)">
              {{ itemTitleReplace(extra) }}
            </p>
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </div>
          <div *ngIf="selectedOptionId == extra.Id">
            <div
              class="br-40px bglight"
              (click)="openNextOption(extra, item)"
              *ngFor="let item of extra.Choose"
              [ngStyle]="
                highLightItem(extra.Id, item)
                  ? {
                      color: SelectedButtonTextColor,
                      'background-color': SelectedButtonColor
                    }
                  : {
                      color: item.TextColor,
                      'background-color': item.BackgroundColor
                    }
              "
            >
              <div class="builderItems">
                <p class="m-0 p-2">{{ item.Item }}</p>
                <p class="m-0" *ngIf="item.Price">
                  {{ item.Price | currency : "GBP" : "symbol" : "1.2-2" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showExtrasAfterMenuBuilder" class="mt-3">
        <div
          *ngFor="let extra of renderedExtraList; let i = index"
          class="card ps-2 pe-2 border-0"
        >
          <div
            class="card-header"
            *ngIf="extra?.Key.toLowerCase().includes('choose')"
          >
            <div class="container">
              <div class="row text-start">
                <div class="col-lg-12 col-md-12" style="margin-bottom: 5px">
                  <button
                    type="button"
                    disabled
                    class="btn btn-warning btn-lg extra-title btnChooseOne"
                  >
                    {{ extra?.Key }}
                  </button>
                </div>
                <br />
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div
                        *ngFor="let item of extra?.extras"
                        class="custom-control custom-radio"
                      >
                        <div class="row mt-3">
                          <div class="col-9">
                            <div class="form-check">
                              <input
                                (click)="mergePopupChoose(item)"
                                type="radio"
                                name="{{ item?.Key }}{{ i }}"
                                id="extra{{ item?.Title }}{{ item?.Key }}{{
                                  i
                                }}"
                                class="form-check-input"
                              />
                              <label
                                class="form-check-label cursor-pointer"
                                for="extra{{ item?.Title }}{{ item?.Key }}{{
                                  i
                                }}"
                              >
                                <p class="m-0">{{ item?.Title }}</p>
                              </label>
                            </div>
                          </div>
                          <div class="col-3">
                            <p *ngIf="item?.Price != 0" class="text-end m-0">
                              {{
                                item?.Price
                                  | currency : "GBP" : "symbol" : "1.2-2"
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-header"
            *ngIf="!extra?.Key.toLowerCase().includes('choose')"
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12" style="margin-bottom: 5px">
                  <button
                    type="button"
                    disabled
                    class="btn btn-warning btn-lg extra-title btnChooseOne"
                  >
                    {{ extra?.Key }}
                  </button>
                  <p class="text-danger m-0" *ngIf="extra?.maxQuantity">
                    Optional: select a maximum of
                    {{ extra.maxQuantity }}
                  </p>
                </div>
                <br />

                <div class="container">
                  <div class="row">
                    <div class="col-md-12 mx-auto">
                      <div
                        *ngFor="let item of extra?.extras; let i = index"
                        class="d-flex justify-content-between flex-row multipleExtras"
                      >
                        <div
                          class="d-flex align-item-center justify-content-between flex-row w-100"
                        >
                          <p class="m-0 w-50 text-start">{{ item?.Title }}</p>
                          <p class="m-0" *ngIf="item.Price">
                            {{
                              (item.Quantity * item.Price
                                ? item.Quantity * item.Price
                                : item.Price
                              ) | currency : "GBP" : "symbol" : "1.2-2"
                            }}
                          </p>
                          <div class="product-quantity">
                            <div class="input-group br-40px border flex-nowrap">
                              <button
                                class="btn"
                                (click)="multipleExtrasQuantity(item)"
                                type="button"
                              >
                                <i class="fas fa-minus"></i>
                              </button>
                              <span class="input-group-text m-0">{{
                                item.Quantity
                              }}</span>
                              <button
                                class="btn"
                                type="button"
                                (click)="mergePopupSelectExtras(item, extra)"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-footer">
      <button
        (click)="
          addToCart(
            filteredRecipe ? filteredRecipe : filteredProduct,
            this.filteredRecipe ? 'recipe' : 'product'
          )
        "
        [disabled]="
          disableCartbtn || renderExtralistChooseOnes !== extraArrayChooseOnes
        "
        class="w-100 recipePopButton"
      >
        <span class="flexDisplay">
          {{ addtoCartButtonText || "Add to cart" }}
          <span>
            {{ recipeTotal | currency : "GBP" : "symbol" : "1.2-2" }}
          </span>
        </span>
      </button>
    </div>
  </div>

  <div
    class="popup br-40px overflow-hidden"
    *ngIf="dineIn"
    (click)="stopPropagation($event)"
  >
    <div class="popup-header">
      <h2 class="modalHeading">Recipe Extras</h2>
      <button type="button" class="close fs-3" (click)="closeModel()">
        &times;
      </button>
    </div>
    <div class="popup-body" *ngIf="filteredRecipe">
      <div class="name padding-10">
        <div *ngIf="filteredRecipe?.RecipeImage">
          <img src="{{ filteredRecipe?.RecipeImage }}" alt="Recipe image" />
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="text-light-black fw-700 font-weight-bolder m-0">
            {{ filteredRecipe?.RecipeTitle }}
          </h2>
          <h5
            class="text-light-black fw-600 no-margin position"
            *ngIf="filteredRecipe?.RecipePrice != 0"
          >
            {{
              filteredRecipe?.RecipePrice
                | currency : "GBP" : "symbol" : "1.2-2"
            }}
          </h5>
        </div>
        <p class="text-start m-0">{{ filteredRecipe?.RecipeDescription }}</p>
      </div>

      <div *ngFor="let extra of renderedExtraList" class="card pt-3">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12" style="margin-bottom: 5px">
              <button
                type="button"
                disabled
                class="btn btn-warning btn-lg extra-title btnChooseOne"
              >
                {{ extra?.Key }}
              </button>
            </div>
            <br />
            <div class="container">
              <div class="row">
                <div class="col-md-10">
                  <div
                    *ngFor="let item of extra?.extras; let i = index"
                    class="p-relative"
                  >
                    <div class="row p-1">
                      <p class="col-9">{{ item?.Title }}</p>
                      <p class="col-3 text-end" *ngIf="item?.Price != 0">
                        {{
                          item?.Price | currency : "GBP" : "symbol" : "1.2-2"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="AddedToCartNotification"
  *ngIf="addedTocart"
  [ngStyle]="{ 'background-color': SelectedButtonColor }"
>
  <h3 class="m-0 w-50 fw-bold" [ngStyle]="{ color: SelectedButtonTextColor }">
    {{ this.quantity + " x " + selectedRecipeTitle }}
  </h3>
  <h3
    class="m-0 w-50 text-center fw-bold"
    [ngStyle]="{ color: SelectedButtonTextColor }"
  >
    {{ "Successfully Added" }}
  </h3>
</div>

<div
  class="AddedToCartNotification"
  *ngIf="limitReached"
  [ngStyle]="{ 'background-color': SelectedButtonColor }"
>
  <h3
    class="m-0 text-center fw-bold"
    [ngStyle]="{ color: SelectedButtonTextColor }"
  >
    Can not add to cart , Max Buy limit Reached for this recipe
  </h3>
</div>
