<hr class="m-0" style="border: 2px solid maroon" />
<div
  class="footerContainer p-2"
  [ngStyle]="{ 'background-color': FooterColor }"
>
  <p>
    <span *ngIf="Footer?.TermsConditions?.Enabled">
      <a
        *ngIf="Footer?.TermsConditions?.URL"
        [href]="Footer?.TermsConditions?.URL"
        target="_blank"
        class="text-danger"
      >
        {{ Footer?.TermsConditions?.Text }}
      </a>

      <a
        *ngIf="!Footer?.TermsConditions?.URL"
        href="javascript:void(0)"
        (click)="openUrl('terms-and-conditions')"
        class="text-danger"
      >
        {{ Footer?.TermsConditions?.Text }}
      </a>
    </span>

    <span
      *ngIf="Footer?.TermsConditions?.Enabled && Footer?.PrivacyPolicy?.Enabled"
    >
      |
    </span>

    <span
      *ngIf="
        Footer?.TermsConditions?.Enabled &&
        !Footer?.PrivacyPolicy?.Enabled &&
        Footer?.Unsubscribe?.Enabled
      "
    >
      |
    </span>

    <span *ngIf="Footer?.PrivacyPolicy?.Enabled">
      <a
        *ngIf="Footer?.PrivacyPolicy?.URL"
        [href]="Footer?.PrivacyPolicy?.URL"
        target="_blank"
        class="text-danger"
        >{{ Footer?.PrivacyPolicy?.Text }}</a
      >

      <a
        *ngIf="!Footer?.PrivacyPolicy?.URL"
        href="javascript:void(0)"
        (click)="openUrl('privacy-policy')"
        class="text-danger"
        >{{ Footer?.PrivacyPolicy?.Text }}</a
      >
    </span>

    <span
      *ngIf="Footer?.PrivacyPolicy?.Enabled && Footer?.Unsubscribe?.Enabled"
    >
      |
    </span>

    <span *ngIf="Footer?.Unsubscribe?.Enabled">
      <a
        href="javascript:void(0)"
        (click)="unsubscribeModel = true; UnsubResult = ''"
        class="text-danger"
        >{{ Footer?.Unsubscribe?.Text }}</a
      >
    </span>
  </p>

  <p>
    <span> Copyright &copy; {{ currentDateStr }}. All Rights Reserved </span>

    <span *ngIf="Footer?.Partners?.Enabled"> | </span>

    <span *ngIf="Footer?.Partners?.Enabled">
      <img
        *ngIf="Footer?.Partners.ImageURL"
        [src]="Footer.Partners.ImageURL"
        style="max-width: 34px; max-width: 34px"
      />
      <a
        *ngIf="Footer?.Partners.URL"
        [href]="Footer?.Partners.URL"
        target="_blank"
        class="text-danger"
      >
        {{ Footer?.Partners.Text }}
      </a>

      <span *ngIf="!Footer?.Partners.URL">
        {{ Footer?.Partners.Text }}
      </span>
    </span>

    <span *ngIf="Footer?.Partners?.Enabled && Footer?.Brand.Enabled"> | </span>

    <span *ngIf="!Footer?.Partners?.Enabled && Footer?.Brand.Enabled"> | </span>

    <span *ngIf="Footer.Brand.Enabled">
      <img
        *ngIf="Footer?.Brand.ImageURL"
        [src]="Footer?.Brand.ImageURL"
        style="max-width: 34px; max-width: 34px"
      />

      <span *ngIf="Footer?.Brand.URL">
        {{ Footer?.Brand.Prefix }}
        <a [href]="Footer?.Brand.URL" target="_blank" class="text-danger">
          {{ Footer?.Brand.Postfix }}
        </a>
      </span>

      <span *ngIf="!Footer?.Brand.URL">
        {{ Footer?.Brand.Prefix }}
      </span>
    </span>
  </p>
</div>

<div class="overlay unsubBox" *ngIf="unsubscribeModel">
  <div class="unsubContent">
    <div class="unsubHead">
      <p class="m-0">UNSUBSCRIPTION</p>
      <button type="button" class="close" (click)="unsubscribeModel = false">
        &times;
      </button>
    </div>
    <div class="unsubBody">
      <p>
        Please enter your details to unsubscribe from
        {{ BrandName }} promotional text
      </p>
      <input
        type="text"
        name="unsubField"
        id="unsubField"
        placeholder="Enter your email/mobile number"
        class="form-control br-40px"
        [(ngModel)]="UnsubField"
      />
      <p
        class="text-success"
        *ngIf="UnsubResult == 'Unsubscribed Successfully'"
      >
        {{ UnsubResult }}
      </p>
      <p
        class="text-danger"
        *ngIf="UnsubResult !== 'Unsubscribed Successfully'"
      >
        {{ UnsubResult }}
      </p>
    </div>
    <div class="unsubFooter">
      <button type="button" class="unsubBtn" (click)="unsubscribe()">
        UNSUBSCRIBE
      </button>
    </div>
  </div>
</div>
